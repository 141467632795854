import numberWithCommas from '../../../Utils/NumberWithCommas'
export default function formatoRut(rut){
    // XX.XXX.XXX-X

    if (rut===null || typeof(rut)=== 'undefined') return null
    const nuevoRut = rut.replace(/\./g,'').replace(/-/g, '').trim().toLowerCase();
    const ultimoDigito = nuevoRut.substr(-1, 1);
    const rutDigito = nuevoRut.substr(0, nuevoRut.length-1)
    let format = numberWithCommas(rutDigito)
    if(rut==="subcargoadm-in") return "SubcargoAdmin"
    // console.log("rut?",{rut})
    if (rut.length!==0) return format.concat('-').concat(ultimoDigito);
    else return null
  }


  