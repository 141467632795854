export default function formatoRutSinpunto(rut){
    // XXXXXXXX-X

    if (rut===null) return null
    const nuevoRut = rut.replace(/\./g,'').replace(/\-/g, '').trim().toLowerCase();
    const ultimoDigito = nuevoRut.substr(-1, 1);
    const rutDigito = nuevoRut.substr(0, nuevoRut.length-1)
    const format = rutDigito
    //console.log(format)
   // console.log(ultimoDigito)
       

    if (rut.length!=0){   
        return format.concat("-",ultimoDigito);
    }
    else return null   
  }

  
