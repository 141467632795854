

export function verificaChile (rutCompleto){
        if (!rutCompleto || rutCompleto.replace(/\./g,"").length < 7) return false
        rutCompleto = rutCompleto.replace(/\./g,"")
        const Modulo11 = (T) =>  {
                var M = 0,
                    S = 1;
                for (; T; T = Math.floor(T / 10))
                    S = (S + T % 10 * (9 - M++ % 6)) % 11;
                return S ? S - 1 : 'k';
        }
        if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
        var tmp = rutCompleto.split('-');
        var digv = tmp[1];
        var rut = tmp[0];
        if (rut.length < 7) return false;
        if (digv == 'K') digv = 'k';
        return (Modulo11(rut).toString() === digv);   
}



// function validateRut(rut) {
//     // Remueve los puntos y el guion
//     rut = rut.replace(/\./g,'').replace('-','');
//     // Verifica que sea un formato válido
//     if (!/^\d+[kK]{1}$/.test(rut)) {
//       return false;
//     }
//     // Divide el RUT en dos partes: el dígito verificador y los dígitos
//     var digits = rut.slice(0, -1);
//     var verifier = rut.slice(-1);
//     // Calcula el dígito verificador esperado
//     var expectedVerifier = '0';
//     var sum = 0;
//     var multiply = 2;
//     for (var i = digits.length - 1; i >= 0; i--) {
//       sum += digits[i] * multiply;
//       if (multiply === 7) {
//         multiply = 2;
//       } else {
//         multiply++;
//       }
//     }
//     var mod = sum % 11;
//     if (mod === 1) {
//       expectedVerifier = 'k';
//     } else if (mod === 0) {
//       expectedVerifier = '0';
//     } else {
//       expectedVerifier = 11 - mod;
//     }
//     // Compara el dígito verificador esperado con el dígito verificador real
//     return verifier.toLowerCase() === expectedVerifier.toLowerCase();
//   }