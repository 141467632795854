import React, {useState} from "react"
import Popup from "reactjs-popup"
import PopupLayout from "../Shared/Layouts/PopupLayout"
import { FORMATO_ONINPUT,FORMATO_MONTOS,FORMATO_INPUTTYPE } from "../../Utils/Formatos/FormatoPaises"
import { AlertaConfirmacion } from "../Shared/GlobalAlerts"
import Auth from "../../middleware/Auth"

const styles = {
    modalContentBook: {
        width: "20%",
        height: "full",
        background: "#FFFFFF",
        minWidth: "520px",
        borderRadius: "0.9rem",
        borderTopColor: "#002758",
        transform: `translate(0, -${window.innerHeight * 0.04}px)`,
}}

const overlayStyle = { background: 'rgba(0,39,88,0.9)' };



export default function Cambio_tonelaje(props){
    const [isFormValid, setIsFormValid] = useState(false);
    const [valorTonelaje, setValorTonelaje] = useState();

    const cambio_tonelaje = (valorTonelaje) => {   
        
        var url = new URL(process.env.REACT_APP_HOST_URL+"api/v1/solicitudes/"+props.solicitud._id+"/cambio_tonelaje")

        var params = {
            valorTonelaje: valorTonelaje,           
        }

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

        fetch(url, {
            method: "GET",
            headers: {
                "x-access-token": Auth.getToken()
            }
        }).then((res) => {          
            return res.json()
          })
          .then((res) => {
           if(res.status == 200){
            window.location.reload();
           }
          })
        
       

    }


    return(
        <Popup
        open={ props.mostrarCambioTonelaje}
        contentStyle={styles.modalContentBook}
        overlayStyle={overlayStyle}
        onClose={() => {props.setMostrarCambioTonelaje(false)}}
        >
            {(close) => (
                <PopupLayout close={close} title={"Editar cantidad de carga"} >
                    <div className="flex justify-center items-center py-4 flex-col"> 
                        <div className="">
                        <label className="pr-4">Toneladas:</label>  
                            <input
                                style={props.edit?{}:{ borderColor: '#CCCCCC', borderWidth: '2px', borderStyle: 'solid' }}
                                placeholder=" X.XXX"
                                value={FORMATO_MONTOS["Perú"](valorTonelaje)}  
                                type = {FORMATO_INPUTTYPE["Perú"]}
                                onInput = {(e)=> FORMATO_ONINPUT["Perú"](e)}   
                                onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    setIsFormValid(
                                        !!(e.target.value )
                                    );  
                                    if (!isNaN(value)) {              
                                        if (/^\d*\.?\d{0,3}$/.test(value)) {                                          
                                            setValorTonelaje(value)
                                        }  
                                    }else{
                                        setValorTonelaje(null) 
                                    }
                                }}
                                
                            />
                            <div className="pt-2 flex justify-center">
                                <button className="py-1 px-2  text-white rounded-lg" 
                                style={{ backgroundColor: isFormValid ? "#002758" : "#CACACA" }}
                                onClick={() => {
                                    if (isFormValid) {
                                        AlertaConfirmacion().fire().then((result) => {
                                            if (result.isConfirmed) {
                                                cambio_tonelaje(valorTonelaje)
                                            }else{
                                                setValorTonelaje(null)
                                                props.setMostrarCambioTonelaje(false)
                                            }
                                        })
                                    }
                                }}
                                disabled={!isFormValid}
                                    >CONFIRMAR
                                </button>
                            </div>
                        </div>
                    </div>
                </PopupLayout>
            )}
        </Popup>
    )
}