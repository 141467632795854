import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "react-phone-number-input/style.css";
import * as serviceWorker from './serviceWorker';
import './tailwind.output.css'

import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next';
// import en from './Translations/en.json'
// import pt_login from './Components/Login/translations/pt.json'
// import es from './Translations/es.json'
// import pt from './Translations/pt.json'

import login_chile from './Components/LoginAndRegister/translations/es-CL.json'
import crear_solicitud_chile from './Components/CrearSolicitud/translations/es-CL.json'
import ver_solicitud_chile from './Components/VerSolicitud/translations/es-CL.json'

import login_peru from './Components/LoginAndRegister/translations/es-PE.json'
import crear_solicitud_peru from './Components/CrearSolicitud/translations/es-PE.json'
import ver_solicitud_peru from './Components/VerSolicitud/translations/es-PE.json'

require('dotenv').config();

// Aquí se hace la magia de traducir el contenido de la página
// Login se dejará para el final, ya que si no se está logeado no se puede saber el idioma!

i18next.init({
  lng: 'es-CL',
  resources: {
    'es-CL': {
     login: login_chile,
     crear_solicitud: crear_solicitud_chile,
     ver_solicitud: ver_solicitud_chile
    },
    'es-PE': {
      login: login_peru,
      crear_solicitud: crear_solicitud_peru,
      ver_solicitud: ver_solicitud_peru
    },
    // pt: {
    //   login: pt_login,
    //   namespace2: pt
    // },
  }
})

ReactDOM.render(
  <React.StrictMode>
     <I18nextProvider i18n={i18next}>  
        <App />
     </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
