// Objeto recibe user.Pais y devuelve el idioma correspondiente
// Uso: Languages[user.Pais]
// Ejemplo: user.Pais = 'Chile' => 'es-CL'

export const Languages = {
    "Perú": "es-PE",
    "Chile": "es-CL",
    "Brasil": "pt-BR",
    "Panamá": "es-PA",
    "México": "es-MX",


    // "Colombia": "es-CO",
    // "Ecuador": "es-EC",
    // "Venezuela": "es-VE",
    // "Guatemala": "es-GT",
    // "Honduras": "es-HN",
    // "Nicaragua": "es-NI",

}