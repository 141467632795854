import Swal from 'sweetalert2';

export const Alert = Swal.mixin({
    customClass: {
        confirmButton: ' rounded-xl bg-[#002758]  text-white py-2 px-12 hover:bg-[#3C9AC5] hover:font-semibold transition duration-400',
        cancelButton: ' mr-12 rounded-xl bg-[#EE4452]  text-white py-2 px-12 hover:bg-[#FF2222] hover:font-semibold transition duration-400',
        title:'',
        htmlContainer:'',
    },
    buttonsStyling: false,
    confirmButtonText: 'Continuar'
})
