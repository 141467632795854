/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import LoginImage from './../Asset/img/login.jpg'
import SubCargo from './../Asset/img/subcargo_blanco.png'
import tecnofast from './../Asset/img/tecnofast.png'
import MapSeguimiento from '../Components/Map/MapSeguimiento';
import socketIOClient from "socket.io-client";
import InformacionGeneral from '../Components/VerSolicitud/modals/InformacionGeneral'
import CargasSeguimiento from '../Components/VerSolicitud/modals/CargasSeguimiento'
import { ListaCargasCompleta } from '../Utils/ListaCargas'
import { ListaCargas, TIPO_DE_ESTRUCTURA_DE_CARGA } from '../Utils/Selects';
import { AlertaError } from '../Components/Shared/GlobalAlerts';
import Auth from '../middleware/Auth';
import { red } from '@material-ui/core/colors'
const ENDPOINT = "https://devapi.subcargo.com:443/"




function SolicitudTiempoRealTecnoFast(props) {
  const [Solicitud, setSolicitud] = useState(null);
  const [Lat, setLat] = useState(0);
  const [Lng, setLng] = useState(0);
  const [Socket, setSocket] = useState(null);


  const start_listen = () => {
    console.log("conecting to:", ENDPOINT + Solicitud._id)

    const socket = socketIOClient(ENDPOINT + Solicitud._id);
    setSocket(socket)
    socket.on("connect", () => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
      console.log(socket.connected);
    });

    socket.on("message", data => {
      // setResponse(JSON.stringify(data));
      if (data.lat && data.lng) {
        setLat(data.lat)
        setLng(data.lng)
      }
    });

  }

  // const send_position = ()=>{
  //   console.log("sending position")
  //   let position = {lat: Lat, lng: Lng}
  //   Socket.emit("message", position)
  // }

  // const desconectar_socket = ()=>{
  //   if(Socket)
  //   Socket.disconnect()
  // }

  const load_last_saved = () => {
    var url = process.env.REACT_APP_HOST_URL + "api/v1/solicitudes/" + Solicitud._id + "/solicitud_tiempo_real"
    fetch(url, {
      method: "GET",
      headers: {
        "x-access-token": Auth.getToken()
      }
    }).then((res) => {
      return res.json()
    }).then((res) => {
      console.log(res)
      if (!res.error && res.GPS) {
        setLat(res.GPS[0])
        setLng(res.GPS[1])
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const loadGpsProvider = () => {
    let camion_id = Solicitud.Camion._id
    var url = process.env.REACT_APP_HOST_URL + "api/v1/camiones/" + camion_id + "/info_gps"
    console.log("InfoGps url", url)

    fetch(url, {
      method: "GET",
      headers: {
        "x-access-token": Auth.getToken()
      }
    }).then((res) => {
      return res.json()
    }).then((res) => {
      // console.log("cargarInfoGps response", res)
      let Selected = res.data.Selected
      // console.log("Selected", Selected)

      // if()
      switch (true) {
        case Selected === 2:
          // // console.log("Entró case 2")
          loop_gps_chile(res.data, Solicitud.Camion.Patente)
          break;
        case Selected === 3:
          // console.log("Entró case 3")
          console.log(res.data)
          loop_Isanto(res.data)
          break;
        default:
          // console.log("Entró caso default")
          start_listen()
          break;
      }


      // setInfoIntegracionGPS(res.data)
      // setSelectedGpsProvider(res.data.Selected)
      // setUsuarioGpsChile(res.data.DataGpsChile.Usuario)
      // setClaveGpsChile(res.data.DataGpsChile.Clave)
      // setReceptorGpsChile(res.data.DataGpsChile.Receptor)
    })
  }


  const guardar_ubicacion_gps_chile = (lat, lng) => {
    var url = process.env.REACT_APP_HOST_URL + "api/v1/solicitudes/" + Solicitud._id + "/solicitud_tiempo_real"
    let data = {
      GPS: [lat, lng]
    }
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then((res) => {
      return res.json()
    }).then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log(err)
    })
  }

  const loop_Isanto = async (gps_data) => {
    console.log(gps_data);
    var res_get_data = await obtener_actividades_isanto(gps_data.Isanto.Imei)

    if (res_get_data) {
      if (res_get_data.data) {
        let data = res_get_data.data
        console.log("DATAAAAAAAAAAAAAAAAAAAAA ", data);
        let lat = data[data.length - 1].latitud
        let lng = data[data.length - 1].longitud
        setLat(lat)
        setLng(lng)

        guardar_ubicacion_gps_chile(lat, lng)
      }
    }
    var interval = setInterval(async () => {
      console.log("loop_GPS_Isanto" + (new Date()).toLocaleString())

      var res_get_data = await obtener_actividades_isanto(gps_data.Isanto.Imei)

      if (res_get_data) {
        if (res_get_data.data) {
          let data = res_get_data.data
          let lat = data[data.length - 1].latitud
          let lng = data[data.length - 1].longitud
          setLat(lat)
          setLng(lng)

          guardar_ubicacion_gps_chile(lat, lng)
        }
        else {
          clearInterval(interval)
        }
      }
      else {
        clearInterval(interval)
      }
    }, 60000)
  }

  const loop_gps_chile = async (gps_data, Patente) => {
    var res_get_token = await obtener_token_gps_chile(gps_data)
    if (res_get_token.data) {
      if (res_get_token.data.access_token) {

        var res_get_actividades = await obtener_actividades_gps_chile(res_get_token.data.access_token, gps_data.DataGpsChile.Receptor, Patente)

        if (res_get_actividades) {
          if (res_get_actividades.data) {
            let data = res_get_actividades.data
            let lat = data[data.length - 1].latitud
            let lng = data[data.length - 1].longitud
            setLat(lat)
            setLng(lng)

            guardar_ubicacion_gps_chile(lat, lng)
          }
        }
      }
    }
    var interval = setInterval(async () => {
      console.log("loop_gps_chile" + (new Date()).toLocaleString())
      console.log("gps_data", gps_data)
      var res_get_token = await obtener_token_gps_chile(gps_data)
      if (res_get_token.data) {
        if (res_get_token.data.access_token) {

          var res_get_actividades = await obtener_actividades_gps_chile(res_get_token.data.access_token, gps_data.DataGpsChile.Receptor, Patente)

          if (res_get_actividades) {
            if (res_get_actividades.data) {
              let data = res_get_actividades.data
              let lat = data[data.length - 1].latitud
              let lng = data[data.length - 1].longitud
              setLat(lat)
              setLng(lng)

              guardar_ubicacion_gps_chile(lat, lng)
            } else {
              clearInterval(interval)
            }
          } else {
            clearInterval(interval)
          }
        } else {
          clearInterval(interval)
        }
      } else {
        clearInterval(interval)
      }


    }, 60000)
  }

  const obtener_actividades_isanto = (imei) => {
    // var url = "http://apigps.gpschile.com:28080/actividad/service/v1/public/receptores/730/dispositivos/actividad"

    var url = process.env.REACT_APP_HOST_URL + "api/v1/isanto/obtener_gps_imei"
    var data = {
      Imei: imei
    }

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then((res) => {
      return res.json()
    }).then((res) => {
      console.log("actividades gps isanto", res)
      return res

    }).catch((err) => {
      console.log("err", err)
      return false
    })
  }

  const obtener_token_gps_chile = (data) => {
    // var url = "http://apigps.gpschile.com:28080/auth/token"
    console.log("obtener_token_gps_chile!!", data)
    var url = process.env.REACT_APP_HOST_URL + "api/v1/gps_chile/obtener_token"
    data =
    {
      Usuario: data.DataGpsChile.Usuario,
      Clave: data.DataGpsChile.Clave
    }

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then((res) => {
      return res.json()
    }).then((res) => {
      console.log("res obtener_token_gps_chile!!", res)
      return res
      // obtener_actividades_gps_chile(res.data.access_token)
    }).catch((err) => {
      console.log("err", err)
      return err
    })

  }

  const obtener_actividades_gps_chile = (token, receptor, patente = null) => {
    // var url = "http://apigps.gpschile.com:28080/actividad/service/v1/public/receptores/730/dispositivos/actividad"

    var url = process.env.REACT_APP_HOST_URL + "api/v1/gps_chile/obtener_actividades"
    var data = {
      token: token,
      receptor: receptor
    }
    if (patente) data.patente = patente

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then((res) => {
      return res.json()
    }).then((res) => {
      console.log("actividades gps chile", res)
      return res

    }).catch((err) => {
      console.log("err", err)
      return false
    })
  }


  const load_solicitud = () => {
    const params = new URLSearchParams(props.location.search);
    // const id_solicitud = params.get('solicitud');
    const id_solicitud = '64341606eeb9ff2c7d9ea276'
    const code = params.get('code');

    if (!id_solicitud) {
      AlertaError('Solicitud no encontrada').fire().then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/#/login"
        }
      })
      return null
    }

    var url = process.env.REACT_APP_HOST_URL + "api/v1/solicitudes/" + id_solicitud
    console.log("url ", url)
    fetch(url, {
      method: "GET",
      headers: {
        "x-access-token": Auth.getToken()
      }
    }).then((res) => {
      return res.json()
    }).then((res) => {
      console.log("res solicitud", res)
      if (!res.solicitud) {
        AlertaError('Solicitud no encontrada').fire().then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/#/login"
          }
        })
        return
      }
      console.log("SOL ", res)
      setSolicitud(res.solicitud)
      // if(code == res.solicitud.CodigoCompartir){
      //   setSolicitud(res.solicitud)

      // }else{
      //   AlertaError('Código inválido').fire().then((result) => {
      //     if(result.isConfirmed){
      //       window.location.href="/#/login"
      //     }
      //   })
      //   return
      // }        
    }).catch((err) => {
      console.log("err", err)
    })
  }

  useEffect(() => {
    load_solicitud()
  }, [])

  useEffect(() => {
    if (Solicitud) {
      load_last_saved()
      loadGpsProvider()
    }

  }, [Solicitud])


  const info_solicitud = () => {
    const hora_inicio = new Date(Solicitud.FechaDeSolicitudDeTransporte)

    return (
      <div className="flex flex-col bg-white shadow-xl p-4" style={{ width: '80vw' }}>
        <div className="flex">
          <div className="w-1/2 flex flex-col p-2">


            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Número de solicitud</div><div>{Solicitud.Numero}</div>
            </div>

            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Tipo de carga</div><div>{ListaCargasCompleta[Solicitud.CargaATransportarLista].label}</div>
            </div>

            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Descripcion de carga</div><div> {Solicitud.CargaATransportar} </div>
            </div>

            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Cantidad de carga</div><div> {Solicitud.CantidadDeCarga} {Solicitud.Mts3 ? "[m³]" : "[Ton]"} </div>
            </div>

            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Cantidad de carga</div><div> {Solicitud.CantidadDeCarga} {Solicitud.Mts3 ? "[m³]" : "[Ton]"} </div>
            </div>

            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold">Hora Salida</div>
              <div>
                <p>{hora_inicio.toLocaleDateString()}</p>
                <p>{hora_inicio.toLocaleTimeString()}</p>
              </div>
            </div>
          </div>
          <div className="w-1/2 p-2">
            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Nombre del Conductor</div><div>{Solicitud.Conductor ? Solicitud.Conductor.NombreConductor : ""}</div>
            </div>
            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Rut del Conductor</div><div>{Solicitud.Conductor ? Solicitud.Conductor.username : ""}</div>
            </div>
            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Telefono Conductor</div><div>{Solicitud.Conductor ? Solicitud.Conductor.TelefonoConductor : ""}</div>
            </div>
            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Patente Camion</div><div>{Solicitud.Camion ? Solicitud.Camion.Patente : ""}</div>
            </div>
            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Modelo</div><div>{Solicitud.Camion ? Solicitud.Camion.Modelo : ""}</div>
            </div>
            <div className="flex justify-between border-b-2 border-solid border-gray-400">
              <div className="text-azul font-bold" >Tipo de Estructura</div><div>{TIPO_DE_ESTRUCTURA_DE_CARGA[Solicitud.TipoDeEstructuraDeCarga]}</div>
            </div>
          </div>
        </div>
        <div className="p-8 flex">
          Para mas detalles dirigase a subcargo
          <Link to={'/login'} className="flex flex-row">
            <div className="text-rojo cursor-pointer underline"> &nbsp;Login </div>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="h-screen flex" style={{ backgroundImage: `url(${LoginImage})`, backgroundSize: 'cover' }}>
      <div className="mx-auto rounded my-auto mx-auto">
        <div class="grid grid-cols-4" style={{ width: '99vw' }}>
          <div className="col-start-1 col-end-4 flex p-4 bg-azul justify-between" style={{ width: '99vw' }}>
            <img src={tecnofast} className="h-16" alt="" />
            <img src={SubCargo} className="h-8 my-auto" alt="" />
          </div>
          {
            Solicitud ?
              <>
                <div className="col-start-1 col-end-4 flex">
                  <MapSeguimiento recorrido={Solicitud.Recorrido} lat={Lat} lng={Lng} />
                </div>
                <div className="col-start-4 col-end-5 p-4" style={{ height: '57rem', overflowY: 'scroll', backgroundColor: "#fc4935" }}>
                  <div className="flex flex-col">
                    <div>
                      <img className="my-3" src="http://www.alfagps.cl/rutas/imagenes/Nombre.png" alt="" />
                    </div>
                    <div className='mt-4'>
                      <InformacionGeneral className="my-4" Solicitud={Solicitud} />
                    </div>
                    <div className='mt-4'>
                      <CargasSeguimiento className="my-4" Recorrido={Solicitud.Recorrido} id_solicitud={Solicitud._id}
                        ValorDeCarga={Solicitud.ValorDeCargaATransportar} TerminadoCliente={Solicitud.TerminadoCliente}
                        Estado={Solicitud.Estado} Solicitud={Solicitud} CargaATransportar={Solicitud.CargaATransportar}
                      />
                    </div>
                  </div>
                </div>
              </>
              :
              null
          }
        </div>
      </div>
      {/* <div className="text-white text-center mx-auto flex flex-row justify-center mt-16 text-xs">
        Desarrollado por Subcargo
      </div> */}
    </div>
  )
}

export default SolicitudTiempoRealTecnoFast
