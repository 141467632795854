import formatoRut from "../../Components/LoginAndRegister/services/FormatoRut"
import formatoRutSinpunto from "../../Components/LoginAndRegister/services/FormatoRutSinpunto"
import { formatoPeru, formatoBrasil, formatoMexico } from "../../Components/LoginAndRegister/services/FormatoPaises"
import { verificaChile } from "./VerificadorRuts"
import NumberWithCommas from "../NumberWithCommas"

export const FORMATO_PAISES = {
    "Perú": (rut) => formatoPeru(rut),
    "Chile": (rut) => formatoRut(rut),
    "Panamá": (rut) => rut, // no hay manera de dar formato x_X
    "Brasil": (rut) => formatoBrasil(rut),
    "México": (rut) => formatoMexico(rut)
}

export const VERIFICADOR_RUTS = {
    "Perú": (rut) => true,
    "Chile": (rut) => verificaChile(rut),
    "Panamá": (rut) => rut, // no hay manera de dar formato x_X
    "Brasil": (rut) => formatoBrasil(rut),
    "México": (rut) => formatoMexico(rut)
}

export const USAN_DECIMALES = {
    "Perú": (monto) => monto.toFixed(2),
    "Chile": (monto) => NumberWithCommas(monto),
}

export const FORMATO_MONTOS = {
    "Perú": (monto) => monto,
    "Chile": (monto) => NumberWithCommas(monto),
}

export const FORMATO_ONINPUT = {
    "Perú": (monto) => null,
    "Chile": (e) => e.target.value = e.target.value.replace(/[^0-9]*/g, ''),
}
export const FORMATO_INPUTTYPE = {
    "Perú": "number",
    "Chile": "text",
}
export const CENTRO_MAPA = {
    "Perú": { lat: -12.04318, lng: -77.02824 },
    "Chile": { lat: -35.675147, lng: -71.542969 },
}

export const FORMATO_PAISES_SINPUNTO = {
    "Perú": (rut) => formatoPeru(rut),
    "Chile": (rut) => formatoRutSinpunto(rut),
}

export const NOMBRE_IVA = {
    "Perú": "IGV",
    "Chile": "IVA",
}

export const IVA_PAISES = {
    "Perú": (monto) => (monto * 0.18).toFixed(2),
    "Chile": (monto) => Math.ceil(monto * 0.19),
}



export const Listado_Bancos = {
    "Chile": [
        "BANCO SANTANDER",
        "BANCO SANTANDER BANEFE",
        "SCOTIABANK AZUL",
        "CORPBANCA",
        "BCI-TBANC",
        "BANCO FALABELLA",
        "BANCO ITAU",
        "BANCO DE CHILE / EDWARDS CITI",
        "BANCOESTADO",
        "BANCO BICE",
        "BANCO SECURITY",
        "BANCO CONSORCIO",
        "BANCO RIPLEY",
        "BANCO INTERNACIONAL",
        "SCOTIABANK",
        "PREPAGO LOS HÉROES",
    ],

    "Perú": [
        "Banco de Comercio",
        "Banco de Crédito del Perú",
        "Banco Interamericano de Finanzas (BanBif)",
        "Banco Pichincha",
        "BBVA",
        "Citibank Perú",
        "Interbank",
        "MiBanco",
        "Scotiabank Perú",
        "Banco GNB Perú",
        "Banco Falabella",
        "Banco Ripley",
        "Banco Santander Perú",
        "Alfin Banco",
        "Bank of China",
        "ICBC PERU BANK",
        "Agrobanco",
        "Banco de la Nación"
    ]
}


export const Listado_TipoDeCuentas = {
    "Chile": [
        "CUENTA CORRIENTE",
        "CUENTA VISTA",
        "CHEQUERA ELECTRÓNICA",
        "CUENTA DE AHORRO",
    ],
    "Perú": [
        "Cuenta Corriente",
        "Cuenta de Ahorro",
        "Cuenta de Plazo Fijo",
        "Cuenta Sueldo",
    ]
}

export const TABLA_SEGUROS = {
    "Chile":
        [
            { label: "0 [UF]", value: 0 },
            { label: "1000 [UF]", value: 1000 },
            { label: "1500 [UF]", value: 1500 },
            { label: "2000 [UF]", value: 2000 },
            { label: "3000 [UF]", value: 3000 },
            { label: "4000 [UF]", value: 4000 },
            { label: "5000 [UF]", value: 5000 },
            { label: "7000 [UF]", value: 7000 },
            { label: "10000 [UF]", value: 10000 },
            { label: "11000 [UF]", value: 11000 },
            { label: "12000 [UF]", value: 12000 },
            { label: "13000 [UF]", value: 13000 },
            { label: "14000 [UF]", value: 14000 },
            { label: "15000 [UF]", value: 15000 },
            { label: "16000 [UF]", value: 16000 },
            { label: "17000 [UF]", value: 17000 },
            { label: "18000 [UF]", value: 18000 },
            { label: "19000 [UF]", value: 19000 },
            { label: "20000 [UF]", value: 20000 },
            { label: "21000 [UF]", value: 21000 },
            { label: "22000 [UF]", value: 22000 },
            { label: "23000 [UF]", value: 23000 },
            { label: "24000 [UF]", value: 24000 },
            { label: "25000 [UF]", value: 25000 },
            { label: "26000 [UF]", value: 26000 },
            { label: "27000 [UF]", value: 27000 },
            { label: "28000 [UF]", value: 28000 },
            { label: "29000 [UF]", value: 29000 },
            { label: "30000 [UF]", value: 30000 },
            { label: "50000 [UF]", value: 50000 }
        ],
    "Perú":
        [
            { label: "0 PEN", value: 0 },
            { label: "150.000 PEN", value: 150000 },
            { label: "250.000 PEN", value: 250000 },
            { label: "300.000 PEN", value: 300000 },
            { label: "450.000 PEN", value: 450000 },
            { label: "650.000 PEN", value: 650000 },
            { label: "800.000 PEN", value: 800000 },
            { label: "1.100.000 PEN", value: 1100000 },
            { label: "1.550.000 PEN", value: 1550000 },
            { label: "1.700.000 PEN", value: 1700000 },
            { label: "1.900.000 PEN", value: 1900000 },
            { label: "2.000.000 PEN", value: 2000000 },
            { label: "2.200.000 PEN", value: 2200000 },
            { label: "2.350.000 PEN", value: 2350000 },
            { label: "2.500.000 PEN", value: 2500000 },
            { label: "2.650.000 PEN", value: 2650000 },
            { label: "2.800.000 PEN", value: 2800000 },
            { label: "3.000.000 PEN", value: 3000000 },
            { label: "3.150.000 PEN", value: 3150000 },
            { label: "3.300.000 PEN", value: 3300000 },
            { label: "3.450.000 PEN", value: 3450000 },
            { label: "3.600.000 PEN", value: 3600000 },
            { label: "3.750.000 PEN", value: 3750000 },
            { label: "3.900.000 PEN", value: 3900000 },
            { label: "4.100.000 PEN", value: 4100000 },
            { label: "4.250.000 PEN", value: 4250000 },
            { label: "4.400.000 PEN", value: 4400000 },
            { label: "4.550.000 PEN", value: 4550000 },
            { label: "4.700.000 PEN", value: 4700000 },
            { label: "7.800.000 PEN", value: 7800000 }]
}

export const TiposDeEstructuraDeCarga = {
    "Chile": [
        [],
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "12", "13", "14", "15", "16", "17", "26", "31"], //Camión Simple
        ["0"],
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "13", "15", "16", "27", "31"],  // Tracto Camión
        ["1", "2", "5", "15"],
        ["0", "1", "2"],
        ["0"],
        ["0"],
        ["30"]
    ],

    "Perú": [
        [],
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "12", "13", "14", "15", "16", "17", "26", "31"], //Camión Simple
        ["0"],
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "13", "15", "16", "27", "29", "31"],  // Tracto Camión
        ["1", "2", "5", "15"],
        ["0", "1", "2"],
        ["0"],
        ["0"],
        ["30"]
    ]
}

export const TiposDeEstructuraDeCarga_CrearSolicitud = {
    "Chile": [
        [],
        ["1", "2", "5", "9", "12", "16", "17", "19", "21", "26", "28", "31"],  // CAMION SIMPLE
        ["0"],
        ["1", "2", "5", "6", "7", "9", "11", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "28", "31"], // Tracto Camión
        ["1", "2", "5", "15"],
        ["0", "1", "2"],
        ["0"],
        ["0"],
        ["0"]
    ],

    "Perú": [
        [],
        ["1", "2", "5", "12", "16", "17", "19", "21", "26", "28", "31"],  // CAMION SIMPLE
        ["0"],
        ["1", "2", "5", "6", "7", "11", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "28", "29", "31"], // Tracto Camión
        ["1", "2", "5", "15"],
        ["0", "1", "2"],
        ["0"],
        ["0"],
        ["30"]
    ]
}

export const TIPO_DE_ESTRUCTURA_DE_CARGA = {
    "Chile": [
        "No Aplica", //0
        "Caja Cerrada Carga Seca", //1
        "Caja Cerrada Carga Refrigerada",//2
        "Jaula Ganadera",//3
        "Jaula Enlonada",//4
        "Plataforma",
        "Plataforma para Contenedores",
        "Cama Baja",
        "Carga Granel (Productos Alimenticios)",
        "Trailer Tanque (Para liquidos / Gases / Asfaltos / Quimicos)",
        "Porta Vehiculos", // 10
        "Batea",
        "Camion Tolva (Escombros / Aridos / Materiales de Construcción)",
        "Porta Maderas",
        "Mixer",
        "No Aplica", // 15
        "Sider",
        "Pluma",
        "Plataforma Rebajada",//18
        "Plataforma Parapeto",
        "Plataforma Extensible",//20
        "Plataforma Grua",
        "Cama Baja Con guinche", //22
        "Cama Baja Cuello Cisne",
        "Cama Baja Modulo",//24
        "Cama Baja Palote",
        "AMPLIROLL",//26
        "MODULOS",//27
        "Plataforma Grúa hidráulica", // 28
        "Aljibe"
    ],

    "Perú": [
        "No Aplica", //0
        "Furgón", //1
        "Caja Cerrada Carga Refrigerada",//2
        "Jaula Ganadera",//3
        "Jaula Enlonada",//4
        "Plataforma",
        "Plataforma para Contenedores",
        "Cama Baja",
        "Carga Granel (Productos Alimenticios)",
        "Trailer Tanque (Para liquidos / Gases / Asfaltos / Quimicos)",
        "Porta Vehiculos", // 10
        "Batea",
        "Camion Tolva (Escombros / Aridos / Materiales de Construcción)",
        "Porta Maderas",
        "Mixer",
        "No Aplica", // 15
        "Sider",
        "Pluma",
        "Plataforma Rebajada",//18
        "Plataforma Parapeto",
        "Plataforma Extensible",//20
        "Plataforma Grua",
        "Cama Baja Con guinche", //22
        "Cama Baja Cuello Cisne",
        "Cama Baja Modulo",//24
        "Cama Baja Palote",
        "AMPLIROLL",//26
        "MODULOS",//27
        "Plataforma Grúa hidráulica", // 28
        "Cama cuna", // 29
        "Maquinaria",
        "Cisterna"
    ]
}

export const TipoCamionPais = {
    "Chile": {
        0: "Seleccione", // 0 NO QUITAR!!!!
        1: "Camión Simple",
        2: "Camión Simple con Remolque",
        3: "Tracto Camión",
        //4 : "Camión 3/4",
        5: "Furgones",
        //6 : "Boxer",
        //7 : "Camionetas" ,
        // 8: "Maquinaria"
    },
    "Perú": {
        0: "Seleccione", // 0 NO QUITAR!!!!
        1: "Camión Simple",
        2: "Camión Simple con Remolque",
        3: "Tracto Camión",
        //4 : "Camión 3/4",
        // 5: "Furgones",
        //6 : "Boxer",
        //7 : "Camionetas" ,
        8: "Maquinaria"
    }
}