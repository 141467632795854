import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faEdit } from "@fortawesome/free-solid-svg-icons";
import { ListaCargas, TipoCamionFiltrado, TIPO_DE_ESTRUCTURA_DE_CARGA } from "../../../Utils/Selects";
import NumberWithCommas from "../../../Utils/NumberWithCommas";
import { useTranslation } from 'react-i18next';
import Cambio_tonelaje from "../cambio_tonelaje"
import { AlertaLoading ,AlertaError} from "../../Shared/GlobalAlerts";
import Auth from "../../../middleware/Auth";
import { ListaCargasCompleta } from "../../../Utils/ListaCargas";

const user = JSON.parse(localStorage.getItem("subcargo_user_loged"))

export default function InformacionGeneral({ Solicitud, reload_solicitud }) {
    const [modalState, setModalState] = useState(false)
    const [nombreContrato, setNombreContrato] = useState("")
    const [showFechasTentativas, setShowFechasTentativas] = useState(false)
    const [tieneRestricciones,settieneRestricciones] = useState(false)
    const { t } = useTranslation("ver_solicitud")


    const [mostrarCambioTonelaje, setMostrarCambioTonelaje] = useState(false);

    const cambiar_tonelaje = () => {
        setMostrarCambioTonelaje(true);
    };


    const load_contrato = () => {
        if (!Solicitud.Contrato) { return }

        if (Solicitud.Contrato?.nombre) {
            setNombreContrato(Solicitud.Contrato.nombre)
            return
        }
        var url = process.env.REACT_APP_HOST_URL + "api/v1/contratos/" + Solicitud.Contrato
        fetch(url, { method: "GET" })
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                setNombreContrato(res.contrato[0]?.nombre)
            })
            .catch((err) => {
                console.log("err", err)
            })
    }

    const load_restricciones = ()=>{
        if(Solicitud.Contrato){
            var url = process.env.REACT_APP_HOST_URL+"api/v1/contratos/"+Solicitud.Contrato._id+"/restricciones"
            fetch(url, {
                method: "GET",
                headers:{
                "x-access-token": Auth.getToken()
                }
            }).then((res)=>{
                return res.json()
            }).then((res) => {
                console.log("resss",res)
                if (res.length > 0) settieneRestricciones(true)
                else settieneRestricciones(false)
            }).catch((err)=>console.log(err))
        }
    }


    useEffect(() => {
        load_contrato();
        load_restricciones();
        console.log("user",user)
    }, []);


    const handleModal = () => {
        setModalState(!modalState)
    }

    var cargas = []
    console.log('Solicituuud', Solicitud)
    if (Solicitud.Recorrido.length > 0 && Solicitud.Recorrido[0].descripcion && Solicitud.CargaGeneralLista) {

        Solicitud.CargaGeneralLista.forEach((carga) => {
            cargas.push(carga.label)
        })
    }


    const hora_inicio = new Date(Solicitud.FechaDeSolicitudDeTransporte)
    const hora_llegada = new Date(Solicitud.HoraDeLlegada)
    return (
        <div>
            {modalState ?
                <div className="w-full bg-white border rounded-md px-4" style={{ borderColor: "#009DDC" }}>
                    <div className="flex justify-end float-right">
                        <FontAwesomeIcon icon={faChevronUp} color="#009DDC" size={"lg"}
                            className=" cursor-pointer float-right mr-1"
                            onClick={() => { handleModal() }}
                        />
                    </div>
                    <div className="text-azul font-bold">Información general</div>
                    <div className="text-rojo2 font-bold my-4">Datos de cliente</div>
                    <div className="Datos Cliente">
                        <div className="flex flex-row">
                            <label className="w-2/5">Título de solicitud</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2 text-sm">{Solicitud.NombreClienteDeDestino}</div>
                        </div>
                        <div className="flex flex-row">
                            <label className="w-2/5">Inicio</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2 text-sm">

                                {Solicitud.ModeloDeSolicitud !== 3 ?
                                    <>
                                        {hora_inicio.toLocaleDateString()} - {hora_inicio.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} hrs. <br />
                                    </>
                                    :
                                    Solicitud.Estado === 1 ?
                                        <>
                                            {Solicitud.FechasTentativas?.map((fecha) => (
                                                <div className='text-center w-full flex flex-wrap'> <div className='font-bold'>{new Date(fecha.fecha).toLocaleDateString() + "\u00A0"}</div>{fecha.fecha ? new Date(fecha.horaInicio).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + " - " + new Date(fecha.horaTermino).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + " hrs" : null}</div>
                                            ))
                                            }
                                        </>
                                        :
                                        <>
                                            {hora_inicio.toLocaleDateString()} - {hora_inicio.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} hrs. <br />
                                        </>

                                }

                                {Solicitud.DireccionDeInicio}


                            </div>
                        </div>
                        <div className="flex flex-row">
                            <label className="w-2/5">Término</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2 text-sm">
                                {Solicitud.ModeloDeSolicitud !== 3 &&
                                    <>
                                        {hora_llegada.toLocaleDateString()} - {hora_llegada.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} hrs. <br />
                                    </>
                                }
                                {
                                    Solicitud.ModeloDeSolicitud === 3 && (

                                        Solicitud.Estado === 1 ?
                                            <>
                                                Duración de viaje: {Solicitud.DuracionDeRutaYCarga} <br />
                                            </>
                                            :
                                            <>
                                                {hora_llegada.toLocaleDateString()} - {hora_llegada.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} hrs. <br />
                                            </>
                                    )
                                }
                                {Solicitud.DireccionDeTermino}
                            </div>
                        </div>
                        {Solicitud.ModeloDeSolicitud === 3 && Solicitud.Estado !== 1 &&
                            <div className="flex flex-row">
                                <label className="w-2/5">Disponibilidad ingresada para iniciar viaje</label>
                                <div className="bg-input text-input w-3/5 mb-1 px-2 text-sm">
                                    {Solicitud.FechasTentativas?.map((fecha) => (
                                        <div className='text-center w-full flex flex-wrap'> <div className='font-bold'>{new Date(fecha.fecha).toLocaleDateString() + "\u00A0"}</div>{fecha.fecha ? new Date(fecha.horaInicio).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + " - " + new Date(fecha.horaTermino).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + " hrs" : null}</div>
                                    ))
                                    }</div>
                            </div>
                        }

                    </div>
                    <div className="text-rojo2 font-bold my-4">Datos de carga</div>
                    <div className="Datos De Carga">

                        {[6].includes(Solicitud.ModeloDeSolicitud) ? null :
                            <div className="flex flex-row">
                                <label className="w-2/5">Carga a transportar</label>
                                <div className="bg-input text-input w-3/5 mb-1 px-2">{cargas.length > 0 ? cargas.join(", ") : ListaCargasCompleta[Solicitud.CargaATransportarLista].label}</div>
                            </div>
                        }
                        <div className="flex flex-row">
                            <label className="w-2/5">Valor de carga</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{t("$") + NumberWithCommas(Solicitud.ValorDeCargaATransportar) + "  " + Solicitud.MonedaTransaccion}</div>
                        </div>
                        <div className="flex flex-row">
                            <label className="w-2/5">Descripcion</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{Solicitud.CargaATransportar}</div>
                        </div>
                    </div>
                    <div className="text-rojo2 font-bold my-4">Datos de camión</div>
                    <div className="Datos De Carga">
                        <div className="flex flex-row">
                            <label className="w-2/5">Tipo de camión</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{t(TipoCamionFiltrado[Solicitud.TipoDeCamion])}</div>
                        </div>
                        <div className="flex flex-row">
                            <label className="w-2/5">Tipo de estructura de carga</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{TIPO_DE_ESTRUCTURA_DE_CARGA[Solicitud.TipoDeEstructuraDeCarga]}</div>
                        </div>
                        {[6].includes(Solicitud.ModeloDeSolicitud) ? null :
                            <div className="flex flex-row">
                                <label className="w-2/5">Cantidad de carga</label>
                                <div className="bg-input text-input w-2/5 mb-1 px-2">{NumberWithCommas(Solicitud.CantidadDeCarga)} {Solicitud.Mts3 ? "[m³]" : "[Ton]"}</div>
                                {[5, 9, 10, 11, 12, 13, 14, 15].includes(Solicitud.Estado) && user.EsSubcargo && !Solicitud.TerminadoCliente
                                    && !Solicitud.DetalleSobrecostos.Terminado && Solicitud.TipoDeSolicitud == "3"

                                    ?
                                    <button className="pl-2" onClick={() => {                                              
                                                                      if(Solicitud.Contrato.ContratoOculto == true) AlertaError("La solicitud se encuentra con el contrato oculto").fire()
                                                                      else if(!tieneRestricciones) AlertaError("La solicitud se encuentra sin restricciones").fire()
                                                                      else cambiar_tonelaje()                                                                 
                                                                    }}>
                                        <FontAwesomeIcon icon={faEdit} color="#002758" size={"lg"} />
                                    </button>
                                    : null}

                            </div>
                        }
                        {(Solicitud?.TonelajeBrazo >= 0 && Solicitud?.TipoDeEstructuraDeCarga == 17) && 
                        <div className="flex flex-row">
                            <label className="w-2/5">Capacidad de levante del brazo</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{Solicitud.TonelajeBrazo} [Ton]</div>
                        </div>}
                        <div className="flex flex-row">
                            <label className="w-2/5">Cantidad de pallets</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{Solicitud.NPallets}</div>
                        </div>
                        <div className="flex flex-row">
                            <label className="w-2/5">Alto / Ancho / Largo [mts]</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{Solicitud.DAlto + " / " + Solicitud.DAncho + " / " + Solicitud.DLargo}</div>
                        </div>
                        <div className="flex flex-row">
                            <label className="w-2/5">Personal extra</label>
                            {Solicitud.NecesitaPersonalExtra === "No" &&
                                <div className="bg-input text-input w-3/5 mb-1 px-2">{Solicitud.NecesitaPersonalExtra}</div>
                            }
                            {
                                Solicitud.NecesitaPersonalExtra === "Si" &&
                                <div className="bg-input text-input w-3/5 mb-1 px-2 flex">
                                    <div className="mr-10">{`Chofer: ${Solicitud.NChoferesASolicitar}`}</div>
                                    <div className="ml-10">{`Peoneta: ${Solicitud.NPersonasASolicitar}`}</div>
                                </div>
                            }
                        </div>


                        {/* <div className="flex flex-row">
                            <label className="w-2/5">Año minimo camión</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{Solicitud.anoMinimoCamion ? Solicitud.anoMinimoCamion : 0}</div>
                        </div>
                        {Solicitud.TipoDeCamion === "3" &&
                            <div className="flex flex-row">
                                <label className="w-2/5">Año minimo acople</label>
                                <div className="bg-input text-input w-3/5 mb-1 px-2">{Solicitud.anoMinimoAcople ? Solicitud.anoMinimoAcople : 0}</div>
                            </div>
                        } */}
                    </div>
                    <div className="text-rojo2 font-bold my-4">Datos de seguimiento</div>
                    <div className="Datos De Carga">
                        <div className="flex flex-row" style={!Solicitud.Contrato ? { display: 'none' } : null}>
                            <label className="w-2/5">Contrato</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{nombreContrato}</div>
                        </div>
                        <div className="flex flex-row">
                            <label className="w-2/5">ID de solicitud de transporte</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{Solicitud.IdSolicitudDeTransporte}</div>
                        </div>
                        <div className="flex flex-row">
                            <label className="w-2/5">Porcentaje de ocupación</label>
                            <div className="bg-input text-input w-3/5 mb-1 px-2">{Solicitud.OcupacionCamion ? Solicitud.OcupacionCamion : "No ingresado"}</div>
                        </div>
                        {
                            !Solicitud.CamposOpcionales ? null :
                                Solicitud.CamposOpcionales.map((campo) => {
                                    return (
                                        <>
                                            <div className="flex flex-row">
                                                <label className="w-2/5">{campo.Titulo}</label>
                                                <div className="bg-input text-input w-3/5 mb-1 px-2">{campo.Valor}</div>
                                            </div>

                                        </>
                                    )
                                })
                        }

                    </div>
                </div>
                : <div className="px-4 pt-2 pb-4 rounded-md" style={{ backgroundColor: '#EEEBEB' }}>
                    <div className="flex justify-end float-right">
                        <FontAwesomeIcon icon={faChevronDown} color="#009DDC" size={"lg"}
                            className=" cursor-pointer float-right mr-1"
                            onClick={() => { handleModal() }}
                        />
                    </div>
                    <div className="text-azul font-bold">Información general</div>
                    <div className="flex flex-row gap-x-8">
                        <label className="w-2/5">Inicio de viaje</label>
                        <label className="justify-start font-bold">
                            {Solicitud.ModeloDeSolicitud !== 3 ?
                                <>
                                    {hora_inicio.toLocaleDateString()} - {hora_inicio.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} <br />
                                </>
                                :
                                Solicitud.ModeloDeSolicitud === 3 && Solicitud.Estado === 1 ?
                                    <>
                                        {Solicitud.FechasTentativas?.length + " fechas tentativas de inicio"}  <br />
                                    </>
                                    :
                                    <>
                                        {hora_inicio.toLocaleDateString()} - {hora_inicio.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} hrs. <br />
                                    </>
                            }
                            {Solicitud.DireccionDeInicio}
                        </label>
                    </div>
                </div>}


            <Cambio_tonelaje
                mostrarCambioTonelaje={mostrarCambioTonelaje} setMostrarCambioTonelaje={setMostrarCambioTonelaje}
                solicitud={Solicitud}

            />
        </div>

    )

}