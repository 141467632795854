export default function NumberWithCommas(x) {
    if (x != null) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    } else {
      return ''; // Devuelve una cadena vacía si el valor es null o es NaN
    }
  }

 