import { SALFA } from "./Selects";

export const ListaCargasCompleta = {
0:{label: "SELECCIONE", peligroso:undefined},
1:{label: "ACEITES",peligroso:false},
2:{label: "ARIDOS",peligroso:false},
3:{label: "ACEITES Y GRASAS COMESTIBLES",peligroso:false},
4:{label: "ALFOMBRAS",peligroso:false},
5:{label: "ALGODÓN",peligroso:false},
6:{label: "ALIMENTACIÓN ANIMAL",peligroso:false},
7:{label: "ACERO",peligroso:false},
8:{label: "ARTÍCULOS DE ACERO",peligroso:false},
9:{label: "ARTÍCULOS DE METALES BÁSICOS",peligroso:false},
10:{label: "METALES BASICOS",peligroso:false},
11:{label: "ARTÍCULOS TEXTILES EXCLUYENDO VESTUARIO DEMARCA",peligroso:false},
12:{label: "TEXTILES",peligroso:false},
13:{label: "AZÚCAR REFINADA",peligroso:false},
14:{label: "BARRAS DE ALUMINIO",peligroso:false},
15:{label: "ALUMINIO",peligroso:false},
16:{label: "BATERÍAS Y CELDAS",peligroso:true},
17:{label: "CELDAS DE CARGA",peligroso:true},
18:{label: "BEBIDAS",peligroso:false},
19:{label: "BEBIDAS ALCOHÓLICAS",peligroso:true},
20:{label: "ALCOHOLES",peligroso:true},
21:{label: "BICICLETAS",peligroso:true},
22:{label: "CAFÉ",peligroso:false},
23:{label: "CARGA GENERAL DE RETAIL Y TIENDAS ELECTRÓNICAS",peligroso:true},
24:{label: "CARNE FRESCA ",peligroso:true},
25:{label: "CARNE POLLO-CERDO (FRESCA)-CARNES (CONGELADA y REFRIGERADA)",peligroso:true},
26:{label: "POLLO",peligroso:true},
27:{label: "CERDO",peligroso:true},
28:{label: "CAUCHO",peligroso:false},
29:{label: "CEMENTO",peligroso:false},
30:{label: "CERÁMICO",peligroso:false},
31:{label: "CEREALES",peligroso:false},
32:{label: "CINC Y SUS MANUFACTURAS",peligroso:false},
33:{label: "COBRE Y SUS MANUFACTURAS ",peligroso:true},
34:{label: "COJINES",peligroso:false},
35:{label: "COLCHONES",peligroso:false},
36:{label: "COMBUSTIBLES",peligroso:false},
37:{label: "COMIDA",peligroso:false},
38:{label: "CONFITERÍA",peligroso:false},
39:{label: "CONGELADORES Y OTROS EQUIPOS DE REFRIGERACIÓN O CONGELACIÓN",peligroso:false},
40:{label: "CORCHO",peligroso:false},
41:{label: "CUERO",peligroso:false},
42:{label: "EQUIPOS ELÉCTRICOS",peligroso:false},
43:{label: "FERTILIZANTES",peligroso:false},
44:{label: "FRUTA FRESCA",peligroso:false},
45:{label: "FRUTOS SECOS Y NUECES",peligroso:true},
46:{label: "MAQUINARIA",peligroso:true},
47:{label: "HIERRO O ACERO",peligroso:false},
48:{label: "INGREDIENTES FARMACÉUTICOS",peligroso:false},
49:{label: "INSTRUMENTOS MUSICALES",peligroso:true},
50:{label: "INSUMOS",peligroso:false},
51:{label: "JUEGOS Y ARTÍCULOS DEPORTIVOS",peligroso:true},
52:{label: "ARTICULOS DEPORTIVOS ",peligroso:true},
53:{label: "JUGOS DE FRUTAS (INCLUIDO EL MOSTO DE UVA) Y JUGOS DE VEGETALES",peligroso:false},
54:{label: "JUGUETES ",peligroso:true},
55:{label: "LANA",peligroso:false},
56:{label: "LECHE (PROCESADA",peligroso:false},
57:{label: "LIBROS",peligroso:false},
58:{label: "REVISTAS",peligroso:false},
59:{label: "MADERA ASERRADA O ASTILLADA LONGITUDINALMENTE CORTADA O PELADA",peligroso:false},
60:{label: "MATERIALES INDUSTRIALES Y DE PRODUCCIÓN",peligroso:false},
61:{label: "METALES Y MINERALES EN GENERAL NO PRECIOSO (ORO",peligroso:false},
62:{label: "MINERALES DE COBRE Y SUS CONCENTRADOS",peligroso:true},
63:{label: "MUEBLES",peligroso:false},
64:{label: "PAPEL HIGIÉNICO",peligroso:false},
65:{label: "PAPEL Y CARTÓN",peligroso:false},
66:{label: "CARTON",peligroso:false},
67:{label: "PESCADO",peligroso:true},
68:{label: "PESCADO CONGELADO",peligroso:true},
69:{label: "PETRÓLEO",peligroso:false},
70:{label: "PLÁSTICOS Y SUS MANUFACTURAS",peligroso:false},
71:{label: "PLATA",peligroso:false},
72:{label: "PRODUCTOS ANIMALES (COMESTIBLES) ",peligroso:true},
73:{label: "PRODUCTOS DE CEREALES",peligroso:false},
74:{label: "CEREALES",peligroso:false},
75:{label: "PRODUCTOS DE PAN",peligroso:false},
76:{label: "PAN",peligroso:false},
77:{label: "PRODUCTOS DEL MAR",peligroso:true},
78:{label: "PRODUCTOS FARMACÉUTICOS",peligroso:true},
79:{label: "PRODUCTOS FOTOGRÁFICOS",peligroso:true},
80:{label: "PRODUCTOS QUÍMICOS",peligroso:false},
81:{label: "PULPA DE MADERA",peligroso:false},
82:{label: "REFRIGERADORES",peligroso:false},
83:{label: "ROCAS MINERALES NO PRECIOSO",peligroso:false},
84:{label: "ROPA DE CAMA",peligroso:false},
85:{label: "ROPA DE MARCAS",peligroso:true},
86:{label: "ROPA DE TRABAJO Y SEGURIDAD",peligroso:false},
87:{label: "SEMILLAS ",peligroso:true},
88:{label: "TÉ",peligroso:false},
89:{label: "TELAS (ELEGANTES",peligroso:false},
90:{label: "TELAS DE ALGODÓN",peligroso:false},
91:{label: "TRANSFORMADORES ELÉCTRICOS",peligroso:false},
92:{label: "VARILLAS Y PERFILES",peligroso:false},
93:{label: "VEGETALES Y FRUTAS",peligroso:false},
94:{label: "ZAPATOS Y ZAPATILLAS ",peligroso:true},
95:{label: "ESTRUCTURA METÁLICA",peligroso:false},
96:{label: "CAÑERÍAS",peligroso:false},
97:{label: "MOLDAJE",peligroso:false},
98:{label: "ANDAMIOS",peligroso:false},
99:{label: "CONTENEDORES",peligroso:false},
100:{label: "ESTRUCTURA",peligroso:false},
101:{label: "MATERIALES",peligroso:false},
102:{label: "FIERRO",peligroso:false},
103:{label: "PREFABRICADOS",peligroso:false},
104:{label: "MODULO (OFICINA/HABITACIONAL)",peligroso:false},
105:{label: "AMPLIROLL",peligroso:false},
106:{label: "MULTIBUCKET",peligroso:false},
107:{label: "MÓDULOS",peligroso:false},
108:{label: "ESCOMBROS",peligroso:false},
109:{label: "DESECHOS/RESIDUOS",peligroso:false},
  //aqui parte el listado de salfa
  110:{label: "ACEITES",peligroso:false },
  111:{label: "ACEITES Y GRASAS COMESTIBLES",peligroso:false },
  112:{label: "ALUMINIO",peligroso:false },
  113:{label: "ARIDOS",peligroso:false },
  114:{label: "ARTÍCULOS DE ACERO",peligroso:false },
  115:{label: "ARTÍCULOS DE METALES BÁSICOS",peligroso:false },
  116:{label: "ARTÍCULOS TEXTILES EXCLUYENDO VESTUARIO DEMARCA",peligroso:false },
  117:{label: "BARRAS DE ALUMINIO",peligroso:false },
  118:{label: "BATERÍAS Y CELDAS",peligroso:true },
  119:{label: "CARTON",peligroso:false },
  120:{label: "CAUCHO",peligroso:false },
  121:{label: "CELDAS DE CARGA",peligroso:true },
  122:{label: "CEMENTO",peligroso:false },
  123:{label: "CERÁMICO",peligroso:false },
  124:{label: "CINC Y SUS MANUFACTURAS.",peligroso:false },
  125:{label: "COBRE Y SUS MANUFACTURAS",peligroso:true },
  126:{label: "EQUIPOS ELÉCTRICOS",peligroso:false },
  127:{label: "HIERRO O ACERO",peligroso:false },
  128:{label: "INSUMOS",peligroso:false },
  129:{label: "MADERA ASERRADA O ASTILLADA LONGITUDINALMENTE CORTADA O PELADA",peligroso:false },
  130:{label: "MAQUINARIA",peligroso:true },
  131:{label: "MATERIALES INDUSTRIALES Y DE PRODUCCIÓN",peligroso:false },
  132:{label: "METALES BASICOS",peligroso:false },
  133:{label: "METALES Y MINERALES EN GENERAL NO PRECIOSO (ORO",peligroso:false },
  134:{label: "MINERALES DE COBRE Y SUS CONCENTRADOS",peligroso:true },
  135:{label: "MUEBLES",peligroso:false },
  136:{label: "PAPEL Y CARTÓN",peligroso:false },
  137:{label: "PETRÓLEO",peligroso:false },
  138:{label: "PLÁSTICOS Y SUS MANUFACTURAS",peligroso:false },
  139:{label: "PRODUCTOS QUÍMICOS",peligroso:false },
  140:{label: "PULPA DE MADERA",peligroso:false },
  141:{label: "ROCAS MINERALES NO PRECIOSO",peligroso:false },
  142:{label: "TRANSFORMADORES ELÉCTRICOS",peligroso:false },
  143:{label: "VARILLAS Y PERFILES",peligroso:false },
  144:{label: "ALCHOLES",peligroso:true },
  145:{label: "ALGODÓN",peligroso:false },
  146:{label: "AZÚCAR REFINADA",peligroso:false },
  147:{label: "BEBIDAS",peligroso:false },
  148:{label: "BEBIDAS ALCOHÓLICAS",peligroso:true },
  149:{label: "BICICLETAS",peligroso:true },
  150:{label: "CAFÉ",peligroso:false },
  151:{label: "CARGA GENERAL DE RETAIL Y TIENDAS ELECTRÓNICAS.",peligroso:true },
  152:{label: "CARNE FRESCA",peligroso:true },
  153:{label: "CARNE POLLO-CERDO (FRESCA)-CARNES (CONGELADA y REFRIGERADA)",peligroso:true },
  154:{label: "CERDO",peligroso:true },
  155:{label: "CEREALES",peligroso:false },
  156:{label: "COJINES",peligroso:false },
  157:{label: "COLCHONES",peligroso:false },
  158:{label: "COMIDA",peligroso:false },
  159:{label: "CONFITERÍA",peligroso:false },
  160:{label: "CONGELADORES Y OTROS EQUIPOS DE REFRIGERACIÓN O CONGELACIÓN",peligroso:false },
  161:{label: "CORCHO",peligroso:false },
  162:{label: "CUERO",peligroso:false },
  163:{label: "FERTILIZANTES",peligroso:false },
  164:{label: "FRUTA FRESCA",peligroso:false },
  165:{label: "FRUTOS SECOS Y NUECES",peligroso:true },
  166:{label: "INSTRUMENTOS MUSICALES",peligroso:true },
  167:{label: "JUEGOS Y ARTÍCULOS DEPORTIVOS",peligroso:true },
  168:{label: "JUGOS DE FRUTAS (INCLUIDO EL MOSTO DE UVA) Y JUGOS DE VEGETALES",peligroso:false },
  169:{label: "JUGUETES",peligroso:true },
  170:{label: "LANA",peligroso:false },
  171:{label: "LECHE (PROCESADA)",peligroso:false },
  172:{label: "LIBROS",peligroso:false },
  173:{label: "PAN",peligroso:false },
  174:{label: "PAPEL HIGIÉNICO",peligroso:false },
  175:{label: "PESCADO",peligroso:true },
  176:{label: "PESCADO CONGELADO",peligroso:true },
  177:{label: "PLATA",peligroso:false },
  178:{label: "POLLO",peligroso:true },
  179:{label: "PRODUCTOS ANIMALES (COMESTIBLES)",peligroso:true },
  180:{label: "PRODUCTOS DE CEREALES",peligroso:false },
  181:{label: "PRODUCTOS DE PAN",peligroso:false },
  182:{label: "PRODUCTOS DEL MAR",peligroso:true },
  183:{label: "PRODUCTOS FARMACÉUTICOS",peligroso:true },
  184:{label: "PRODUCTOS FOTOGRÁFICOS",peligroso:true },
  185:{label: "REFRIGERADORES",peligroso:false },
  186:{label: "REVISTAS",peligroso:false },
  187:{label: "ROPA DE CAMA",peligroso:false },
  188:{label: "ROPA DE MARCAS",peligroso:true },
  189:{label: "ROPA DE TRABAJO Y SEGURIDAD",peligroso:false },
  190:{label: "TÉ",peligroso:false },
  191:{label: "TELAS (ELEGANTES)",peligroso:false },
  192:{label: "TELAS DE ALGODÓN",peligroso:false },
  193:{label: "TEXTILES",peligroso:false },
  194:{label: "VEGETALES Y FRUTAS",peligroso:false },
  195:{label: "ZAPATOS Y ZAPATILLAS",peligroso:true },
  196:{label: "COMBUSTIBLES",peligroso:false },
  197:{label: "ALFOMBRAS",peligroso:false },
  198:{label: "ARTICULOS DEPORTIVOS",peligroso:true },
  199:{label: "INGREDIENTES FARMACÉUTICOS",peligroso:false },
  200:{label: "SEMILLAS",peligroso:true },
  201:{label: "ACERO",peligroso:false },
  202:{label: "ALIMENTACIÓN ANIMAL",peligroso:false },
  203:{label: "ABLANDADOR DE AGUA",peligroso:false },
  204:{label: "ACCESORIO BOMBAS",peligroso:false },
  205:{label: "ADAPTADOR CALEFONT",peligroso:false },
  206:{label: "ABRASADERA",peligroso:false },
  207:{label: "ACONDICIONADOR DE AIRE",peligroso:false },
  208:{label: "ACOPLES",peligroso:false },
  209:{label: "ACUMULADOR",peligroso:false },
  210:{label: "ADAPTADOR",peligroso:false },
  211:{label: "ADAPTADOR CORRIENTE",peligroso:false },
  212:{label: "ATRILES",peligroso:false },
  213:{label: "AISLANTE",peligroso:false },
  214:{label: "ADAPTADOR ROSCADO",peligroso:false },
  215:{label: "ALAMBRE",peligroso:false },
  216:{label: "ALBARANES",peligroso:false },
  217:{label: "ALCOTEST",peligroso:false },
  218:{label: "ALERCO",peligroso:false },
  219:{label: "ALZAPRIMA",peligroso:false },
  220:{label: "ANDAMIOS",peligroso:false },
  221:{label: "ANGULO DE BORDE",peligroso:false },
  222:{label: "ARTEFACTO SANITRIO",peligroso:false },
  223:{label: "ANCLAJES",peligroso:false },
  224:{label: "ARTEFACTOS SANITARIOS",peligroso:false },
  225:{label: "ACRILICOS",peligroso:false },
  226:{label: "ARTICULOS DE ASEO",peligroso:false },
  227:{label: "ARTICULOS DE OFICINA",peligroso:false },
  228:{label: "ASIENTOS",peligroso:false },
  229:{label: "ATORNILLADOR",peligroso:false },
  230:{label: "BALDE EXCAVADORA",peligroso:false },
  231:{label: "BALDOSAS",peligroso:false },
  232:{label: "BARANDA TIPO H",peligroso:false },
  233:{label: "BARANDAS",peligroso:false },
  234:{label: "BARRA",peligroso:false },
  235:{label: "BARRERA NEW JERSEY",peligroso:false },
  236:{label: "BASE DE GAS",peligroso:false },
  237:{label: "ADHESIVOS",peligroso:false },
  238:{label: "ACCESORIOS TESTIGUERA",peligroso:false },
  239:{label: "ADAMIOS",peligroso:false },
  240:{label: "ADAPTADORES",peligroso:false },
  241:{label: "ADHESIVO CERAMICO",peligroso:false },
  242:{label: "ADHESIVOS PVC",peligroso:false },
  243:{label: "ADITIVO PERFORACION",peligroso:false },
  244:{label: "ADITIVOS",peligroso:false },
  245:{label: "ADOCRETO",peligroso:false },
  246:{label: "AGUA",peligroso:false },
  247:{label: "AISLADORES",peligroso:false },
  248:{label: "ALCORQUE",peligroso:false },
  249:{label: "BANDEJA",peligroso:false },
  250:{label: "ALICATE",peligroso:false },
  251:{label: "ALIMENTADOR ALAMBRE",peligroso:false },
  252:{label: "ALISADORES",peligroso:false },
  253:{label: "ALSIPERCHA",peligroso:false },
  254:{label: "ALZADOR DE PALLETS",peligroso:false },
  255:{label: "ALZADORA DE HOMBROS",peligroso:false },
  256:{label: "ALZADORA DE PIES",peligroso:false },
  257:{label: "ALZIPERCHA",peligroso:false },
  258:{label: "AMARRA CABLE",peligroso:false },
  259:{label: "AMORTIGUADOR",peligroso:false },
  260:{label: "ANGULO",peligroso:false },
  261:{label: "ANGULOS",peligroso:false },
  262:{label: "ANTEOJOS",peligroso:false },
  263:{label: "APRO",peligroso:false },
  264:{label: "ARCHIVADORES",peligroso:false },
  265:{label: "ARMADURAS",peligroso:false },
  266:{label: "ART DE OFICINA",peligroso:false },
  267:{label: "BAÑERA",peligroso:false },
  268:{label: "ASPIRADORA",peligroso:false },
  269:{label: "BALANCÍN",peligroso:false },
  270:{label: "BALATAS",peligroso:false },
  271:{label: "BALIZA",peligroso:false },
  272:{label: "AIRE ACONDICIONADO",peligroso:false },
  273:{label: "BANANO",peligroso:false },
  274:{label: "BANCA",peligroso:false },
  275:{label: "BANDEJA ANTIDERRAME",peligroso:false },
  276:{label: "BAÑO",peligroso:false },
  277:{label: "BAÑOS QUÍMICOS",peligroso:false },
  278:{label: "BARANDA DE PROTECCION",peligroso:false },
  279:{label: "BARANDLLA",peligroso:false },
  280:{label: "ART FERRETERIA",peligroso:false },
  281:{label: "BARRA DE PERFORACION",peligroso:false },
  282:{label: "BARRA PARA HORMIGON",peligroso:false },
  283:{label: "BARRA ROSCADA",peligroso:false },
  284:{label: "BARRERA ANCLAJE",peligroso:false },
  285:{label: "BARRERA CAMINERA",peligroso:false },
  286:{label: "BARRERA METALICA",peligroso:false },
  287:{label: "ACUÑADOR",peligroso:false },
  288:{label: "BASE ESTABILIZAD",peligroso:false },
  289:{label: "BASE PORTATIL",peligroso:false },
  290:{label: "ALISADORA PAVIMENTO",peligroso:true },
  291:{label: "ACCESORIOS SIDEBOOM",peligroso:true },
  292:{label: "ANTENAS",peligroso:true },
  293:{label: "ANEMOMETRO DIGITAL",peligroso:true },
  294:{label: "BARREDORA INDUSTRIAL",peligroso:true },
  295:{label: "BASE REGULABLE",peligroso:false },
  296:{label: "BASE TANQUE DE AGUA",peligroso:false },
  297:{label: "BASTIDOR DE GRÚA",peligroso:false },
  298:{label: "BASTÓN AUXILIAR IZAJE",peligroso:false },
  299:{label: "BASURERO",peligroso:false },
  300:{label: "BASURERO MUNICIPAL",peligroso:false },
  301:{label: "BASUREROS",peligroso:false },
  302:{label: "BATERIAS",peligroso:false },
  303:{label: "BEKRON",peligroso:false },
  304:{label: "BETONERA",peligroso:false },
  305:{label: "BIBLIOTECA",peligroso:false },
  306:{label: "BICICLETEROS",peligroso:false },
  307:{label: "BIDONES",peligroso:false },
  308:{label: "BIDONES DE AGUA",peligroso:false },
  309:{label: "BISAGRAS",peligroso:false },
  310:{label: "BIT",peligroso:false },
  311:{label: "BLOQUES DE HORMIGÓN",peligroso:false },
  312:{label: "BMV",peligroso:false },
  313:{label: "BOBINAS DE CABLES",peligroso:false },
  314:{label: "BOCINA DE ALTO RUIDO",peligroso:false },
  315:{label: "BODEGA",peligroso:false },
  316:{label: "BOLARDOS DE SEGURIDAD",peligroso:false },
  317:{label: "BOLONES CANTO",peligroso:false },
  318:{label: "BOLSAS DE BASURA",peligroso:false },
  319:{label: "BOLSAS ROPA SUCIA",peligroso:false },
  320:{label: "BOMBA",peligroso:false },
  321:{label: "BOMBA ALTA PRESION",peligroso:false },
  322:{label: "BOMBA DE ADITIVO",peligroso:false },
  323:{label: "BOMBA ESTACIONARIA",peligroso:false },
  324:{label: "BOMBA MICROFILTRADO",peligroso:false },
  325:{label: "BOMBA SUMERGIBLE",peligroso:true },
  326:{label: "BOOMER S2",peligroso:true },
  327:{label: "BOQUILLAS",peligroso:false },
  328:{label: "BOTELLONES DE AGUA",peligroso:false },
  329:{label: "BOTIQUIN",peligroso:false },
  330:{label: "BRAZO GRÚA HIDRÁULICA",peligroso:true },
  331:{label: "BROCAS",peligroso:false },
  332:{label: "BROCHAS",peligroso:false },
  333:{label: "BULLDOZER",peligroso:true },
  334:{label: "BULTOS",peligroso:false },
  335:{label: "BUS",peligroso:false },
  336:{label: "BUZOS",peligroso:false },
  337:{label: "CABALLETES",peligroso:false },
  338:{label: "CABEZALES",peligroso:false },
  339:{label: "CABINA EMERGENCIA",peligroso:false },
  340:{label: "CABINA HIDROTEST",peligroso:false },
  341:{label: "CABINA SOLDADURA",peligroso:false },
  342:{label: "CABINAS",peligroso:false },
  343:{label: "CABLE CALPE",peligroso:false },
  344:{label: "CABLE DE RED",peligroso:false },
  345:{label: "CABLE DESNUDO",peligroso:false },
  346:{label: "CABLE UTP",peligroso:false },
  347:{label: "CABLES",peligroso:false },
  348:{label: "CABLES DE PODER",peligroso:false },
  349:{label: "CABLES LIBRE DE HALOGENOS",peligroso:false },
  350:{label: "CABO DE VIDA",peligroso:false },
  351:{label: "CADENA",peligroso:false },
  352:{label: "CADEX",peligroso:false },
  353:{label: "CAIMAN",peligroso:false },
  354:{label: "CAJAS",peligroso:false },
  355:{label: "CAJON",peligroso:false },
  356:{label: "CAJONES",peligroso:false },
  357:{label: "CAMA BAJA",peligroso:true },
  358:{label: "CAMARA",peligroso:false },
  359:{label: "CAMARA DESGRASADORA",peligroso:false },
  360:{label: "CAMARA NEUMATICO",peligroso:false },
  361:{label: "CAMARAS",peligroso:false },
  362:{label: "CAMIÓN",peligroso:true },
  363:{label: "CAMIÓN 3/4",peligroso:true },
  364:{label: "CAMION ALJIBE",peligroso:true },
  365:{label: "CAMIÓN ARTICULADO",peligroso:true },
  366:{label: "CAMIÓN COMBUSTIBLE",peligroso:true },
  367:{label: "CAMIÓN MINI MIXER",peligroso:true },
  368:{label: "CAMION MIXER",peligroso:true },
  369:{label: "CAMION PLANO",peligroso:true },
  370:{label: "CAMION TOLVA",peligroso:true },
  371:{label: "CAMIONETA",peligroso:true },
  372:{label: "CAMPANA",peligroso:false },
  373:{label: "CANAL",peligroso:false },
  374:{label: "CANAL ACERO GALVÁNICO",peligroso:false },
  375:{label: "CANALES",peligroso:false },
  376:{label: "CANALETAS",peligroso:false },
  377:{label: "CANASTILLOS",peligroso:false },
  378:{label: "CANDADO",peligroso:false },
  379:{label: "CAÑERÍA",peligroso:false },
  380:{label: "CAÑERIA HDPE",peligroso:false },
  381:{label: "CAÑERÍA Y CODOS",peligroso:false },
  382:{label: "CAÑERIAS PVC Y ACERO CARBONO",peligroso:false },
  383:{label: "CAÑERÍAS Y FITTINGS",peligroso:false },
  384:{label: "CAPACHO",peligroso:false },
  385:{label: "CAPACHO DE ESCOMBROS",peligroso:false },
  386:{label: "CAPSULA CADWELD",peligroso:false },
  387:{label: "CARGADOR FRONTAL",peligroso:true },
  388:{label: "CARPA ARAÑA",peligroso:false },
  389:{label: "CARRETE",peligroso:false },
  390:{label: "CARRETE DE CABLE",peligroso:false },
  391:{label: "CARRETES",peligroso:false },
  392:{label: "CARRETILLA",peligroso:false },
  393:{label: "CARRETILLAS",peligroso:false },
  394:{label: "CASETA",peligroso:false },
  395:{label: "CASILLEROS",peligroso:false },
  396:{label: "CATALIZADOR",peligroso:false },
  397:{label: "CAUTIN",peligroso:false },
  398:{label: "CELDAS",peligroso:false },
  399:{label: "CELOSIAS",peligroso:false },
  400:{label: "CENEFA MINERA",peligroso:false },
  401:{label: "CERAMICA",peligroso:false },
  402:{label: "CERCHAS",peligroso:false },
  403:{label: "CERCHAS METALCOM",peligroso:false },
  404:{label: "CERCO",peligroso:false },
  405:{label: "CERCOS PERIMETRALES",peligroso:false },
  406:{label: "CERRADURA",peligroso:false },
  407:{label: "CERRADURAS",peligroso:false },
  408:{label: "CHALECOS GEOLOGOS",peligroso:false },
  409:{label: "CHEQUEADOR",peligroso:false },
  410:{label: "CHICHARRA",peligroso:false },
  411:{label: "CHOCAS",peligroso:false },
  412:{label: "CHOLGUAN",peligroso:false },
  413:{label: "CHUZO",peligroso:false },
  414:{label: "CIERRE PERIMETRAL",peligroso:false },
  415:{label: "CILINDROS",peligroso:false },
  416:{label: "CINTA",peligroso:false },
  417:{label: "CINTA ADHESIVA",peligroso:false },
  418:{label: "CINTA AISLADORA",peligroso:false },
  419:{label: "CINTAS",peligroso:false },
  420:{label: "CLAVOS",peligroso:false },
  421:{label: "COCINILLA",peligroso:false },
  422:{label: "CODO",peligroso:false },
  423:{label: "CODOS",peligroso:false },
  424:{label: "COLLARIN",peligroso:false },
  425:{label: "COLUMNAS",peligroso:false },
  426:{label: "COLUMPIO",peligroso:false },
  427:{label: "COMBO",peligroso:false },
  428:{label: "COMEDOR",peligroso:false },
  429:{label: "COMPACTADOR",peligroso:true },
  430:{label: "COMPONENTES DE MAQUINARIA",peligroso:true },
  431:{label: "COMPONENTES ELECTRICOS",peligroso:true },
  432:{label: "COMPRESOR",peligroso:false },
  433:{label: "CONDUIT",peligroso:false },
  434:{label: "CONECTOR",peligroso:false },
  435:{label: "CONECTORES",peligroso:false },
  436:{label: "CONOS",peligroso:false },
  437:{label: "CONSERVADORA DE ALIMENTOS",peligroso:false },
  438:{label: "CONSOLAS DE IZAJE",peligroso:false },
  439:{label: "CONTENEDOR",peligroso:false },
  440:{label: "CONTRAPESOS",peligroso:false },
  441:{label: "CONTROLADOR AIRE ACONDICIONADO",peligroso:false },
  442:{label: "COPLA",peligroso:false },
  443:{label: "COPLAS",peligroso:false },
  444:{label: "CORDEL",peligroso:false },
  445:{label: "CORDONES",peligroso:false },
  446:{label: "CORREA DESCARGA",peligroso:false },
  447:{label: "CORRUGADOS",peligroso:false },
  448:{label: "CORTADORA DE PAVIMENTO",peligroso:true },
  449:{label: "CRUCETAS",peligroso:false },
  450:{label: "CUBRE CARTONERA",peligroso:false },
  451:{label: "CUCHILLAS",peligroso:false },
  452:{label: "CUNA",peligroso:false },
  453:{label: "CUÑA",peligroso:false },
  454:{label: "CURVA",peligroso:false },
  455:{label: "CURVADORA",peligroso:true },
  456:{label: "DADO",peligroso:false },
  457:{label: "DADO MAGNETICO",peligroso:false },
  458:{label: "DADOS",peligroso:false },
  459:{label: "DARTEL",peligroso:false },
  460:{label: "DECODIFICADORES",peligroso:false },
  461:{label: "DEMOLEDOR",peligroso:false },
  462:{label: "DESAGUE",peligroso:false },
  463:{label: "DESCONECTADOR",peligroso:false },
  464:{label: "DESCONECTADOR TRIFASICO",peligroso:false },
  465:{label: "DESCONECTOR",peligroso:false },
  466:{label: "DESINFECTANTE",peligroso:false },
  467:{label: "DESLIZADERAS",peligroso:false },
  468:{label: "DESMOLDANTE",peligroso:false },
  469:{label: "DETECTOR DE GAS",peligroso:false },
  470:{label: "DILUYENTE",peligroso:false },
  471:{label: "DISCO",peligroso:false },
  472:{label: "DISCO CORTE",peligroso:false },
  473:{label: "DISCOS",peligroso:false },
  474:{label: "DISPENSADOR DE AGUA",peligroso:false },
  475:{label: "DISPENSADORES",peligroso:false },
  476:{label: "DOBLADORA",peligroso:false },
  477:{label: "DROP",peligroso:false },
  478:{label: "DUCTO VENTILACION",peligroso:false },
  479:{label: "DUCTOS",peligroso:false },
  480:{label: "DUMPER",peligroso:false },
  481:{label: "EEM",peligroso:false },
  482:{label: "ELECTRODO",peligroso:false },
  483:{label: "EMPAQUETADURA",peligroso:false },
  484:{label: "ENCHUFES",peligroso:false },
  485:{label: "ENCIMERA",peligroso:false },
  486:{label: "ENFIERRADURA",peligroso:false },
  487:{label: "ENROLLADOR",peligroso:false },
  488:{label: "ENVASES",peligroso:false },
  489:{label: "EPC CURVA",peligroso:false },
  490:{label: "EPC RECTA",peligroso:false },
  491:{label: "EPP",peligroso:false },
  492:{label: "EQUIPO OXICORTE",peligroso:false },
  493:{label: "EQUIPO AGUA LLUVIA",peligroso:false },
  494:{label: "EQUIPO DE EMERGENCIA",peligroso:false },
  495:{label: "EQUIPO DE LIMPIEZ",peligroso:false },
  496:{label: "EQUIPO DE SOLDAR",peligroso:false },
  497:{label: "EQUIPO ELECTRICO",peligroso:false },
  498:{label: "EQUIPO ELECTROFUSION",peligroso:false },
  499:{label: "EQUIPO IMPULSIÓN AGUA",peligroso:false },
  500:{label: "EQUIPO SOLDAR",peligroso:false },
  501:{label: "EQUIPO SONDAJE",peligroso:true },
  502:{label: "EQUIPO TERMOFUSION",peligroso:false },
  503:{label: "EQUIPOS",peligroso:false },
  504:{label: "EQUIPOS CONTRAINCENDIOS",peligroso:false },
  505:{label: "EQUIPOS DE CLIMA",peligroso:false },
  506:{label: "EQUIPOS DE COCINA Y BAÑO",peligroso:false },
  507:{label: "EQUIPOS ELECTRICOS",peligroso:false },
  508:{label: "EQUIPOS MENORES",peligroso:false },
  509:{label: "EQUIPOS MENORES Y MAYORES",peligroso:false },
  510:{label: "EQUIPOS SERVIMAQUI",peligroso:false },
  511:{label: "EQUIPOS VACUUM",peligroso:false },
  512:{label: "ESCALERA",peligroso:false },
  513:{label: "ESCALERA FIBRAVIDRIO",peligroso:false },
  514:{label: "ESCALERAS",peligroso:false },
  515:{label: "ESCALERILLA",peligroso:false },
  516:{label: "ESCALERILLAS",peligroso:false },
  517:{label: "ESCAÑO",peligroso:false },
  518:{label: "ESCAÑO HORMIGON",peligroso:false },
  519:{label: "ESCAÑOS",peligroso:false },
  520:{label: "ESCOBA",peligroso:false },
  521:{label: "ESCOBILLA",peligroso:false },
  522:{label: "ESCOBILLAS",peligroso:false },
  523:{label: "ESCOBILLON",peligroso:false },
  524:{label: "ESCRITORIO",peligroso:false },
  525:{label: "ESCRITORIOS",peligroso:false },
  526:{label: "ESLINGA",peligroso:false },
  527:{label: "ESLINGAS",peligroso:false },
  528:{label: "ESLINGAS TUBULARES",peligroso:false },
  529:{label: "ESMALTE",peligroso:false },
  530:{label: "ESMALTE AL AGUA",peligroso:false },
  531:{label: "ESMALTES",peligroso:false },
  532:{label: "ESMALTES AL AGUA",peligroso:false },
  533:{label: "ESMERIL",peligroso:false },
  534:{label: "ESMERIL ANGULAR",peligroso:false },
  535:{label: "ESPARRAGO",peligroso:false },
  536:{label: "ESPARRAGO ROSCADO",peligroso:false },
  537:{label: "ESPARRAGOS",peligroso:false },
  538:{label: "ESPONJAS",peligroso:false },
  539:{label: "ESPUMA",peligroso:false },
  540:{label: "ESQUIEROS",peligroso:false },
  541:{label: "ESQUINERO LATON",peligroso:false },
  542:{label: "ESQUINEROS",peligroso:false },
  543:{label: "ESTABILIZADOR",peligroso:false },
  544:{label: "ESTABILIZADOR RS 300",peligroso:false },
  545:{label: "ESTACAS",peligroso:false },
  546:{label: "ESTACION",peligroso:false },
  547:{label: "ESTACION DE EMERGENCIA",peligroso:false },
  548:{label: "ESTACION EMERGENCIA TERRENO",peligroso:false },
  549:{label: "ESTACIONES DE HIDRATACION",peligroso:false },
  550:{label: "ESTANQUE",peligroso:false },
  551:{label: "ESTANQUE DE AGUA",peligroso:false },
  552:{label: "ESTANQUE MOVIL",peligroso:false },
  553:{label: "ESTANQUE PETROLEO",peligroso:false },
  554:{label: "ESTANQUE POLIETILENO",peligroso:false },
  555:{label: "ESTANTES",peligroso:false },
  556:{label: "ESTAÑO",peligroso:false },
  557:{label: "ESTROBOS",peligroso:false },
  558:{label: "ESTRUCTURA",peligroso:false },
  559:{label: "ESTRUCTURA GRÚA HIDRÁULICA",peligroso:false },
  560:{label: "ESTRUCTURA Y ALMOHADILLAS DE GRÚA HIDRÁULICA",peligroso:false },
  561:{label: "ESTRUCTURAS",peligroso:false },
  562:{label: "ESTRUCTURAS DE CEMENTO",peligroso:false },
  563:{label: "ESTUCO",peligroso:false },
  564:{label: "ETIQUETAS",peligroso:false },
  565:{label: "ETIQUETAS ADHESIVAS",peligroso:false },
  566:{label: "EXCAVADORA",peligroso:false },
  567:{label: "EXCEDENTES",peligroso:false },
  568:{label: "EXTINTOR",peligroso:false },
  569:{label: "EXTINTORES",peligroso:false },
  570:{label: "EXTRACTORES",peligroso:false },
  571:{label: "FAGÜE",peligroso:false },
  572:{label: "FAROL TRASERO",peligroso:false },
  573:{label: "FELXIBLES",peligroso:false },
  574:{label: "FERRETERIA",peligroso:false },
  575:{label: "FIBRA DE VIDRIO",peligroso:false },
  576:{label: "FIBRA OPTICA",peligroso:false },
  577:{label: "FIERRO",peligroso:false },
  578:{label: "FIERRO ESTRIADO",peligroso:false },
  579:{label: "FIJACION",peligroso:false },
  580:{label: "FILTRO",peligroso:false },
  581:{label: "FILTROS",peligroso:false },
  582:{label: "FITINERIA",peligroso:false },
  583:{label: "FITTING",peligroso:false },
  584:{label: "FLANGE",peligroso:false },
  585:{label: "FLANGES",peligroso:false },
  586:{label: "FLEX",peligroso:false },
  587:{label: "FLEXIBLES",peligroso:false },
  588:{label: "FLEXIBLES Y MONOMANDO",peligroso:false },
  589:{label: "FOCO SOLAR LED",peligroso:false },
  590:{label: "FOTOCOPIADORA",peligroso:false },
  591:{label: "FRAGUADOR",peligroso:false },
  592:{label: "FRAGUE",peligroso:false },
  593:{label: "FRAGUES",peligroso:false },
  594:{label: "FULMINANTE",peligroso:false },
  595:{label: "FUNDA CABLE",peligroso:false },
  596:{label: "FUNDA DE MANDRIL Y SOLDADORA",peligroso:false },
  597:{label: "FUNDA PVC",peligroso:false },
  598:{label: "FUNDACION",peligroso:false },
  599:{label: "FUNDACIONES",peligroso:false },
  600:{label: "FUNDACIONES PARARRAYOS",peligroso:false },
  601:{label: "FUSIBLE",peligroso:false },
  602:{label: "GABINETES DE EMERGENCIA",peligroso:false },
  603:{label: "GALON PINTURA",peligroso:false },
  604:{label: "GALONES DE GAS",peligroso:false },
  605:{label: "GANCHO",peligroso:false },
  606:{label: "GAS",peligroso:false },
  607:{label: "GASES",peligroso:false },
  608:{label: "GATA HIDRAULICA",peligroso:true },
  609:{label: "GENERADOR",peligroso:true },
  610:{label: "GENERADOR 40 KVA",peligroso:true },
  611:{label: "GENERADORES",peligroso:true },
  612:{label: "GEOMEMBRANA",peligroso:false },
  613:{label: "GEOTEXTIL",peligroso:false },
  614:{label: "GEOTEXTIL POLIPROPILENO",peligroso:false },
  615:{label: "GIRADOR",peligroso:false },
  616:{label: "GOLILLA",peligroso:false },
  617:{label: "GOLILLA PLANA",peligroso:false },
  618:{label: "GOLILLAS",peligroso:false },
  619:{label: "GOLILLAS Y CAÑERIAS",peligroso:false },
  620:{label: "GOMA PODOTACTIL",peligroso:false },
  621:{label: "GRADAS",peligroso:false },
  622:{label: "GRADAS PREFABRICADAS",peligroso:false },
  623:{label: "GRAMPAS",peligroso:false },
  624:{label: "GRANALLADORAS Y BETONERAS",peligroso:false },
  625:{label: "GRAPA",peligroso:false },
  626:{label: "GRASA",peligroso:false },
  627:{label: "GRASA LITIO",peligroso:false },
  628:{label: "GRATA ACERO",peligroso:false },
  629:{label: "GRAVILLA",peligroso:false },
  630:{label: "GRIFERIA",peligroso:false },
  631:{label: "GRIFO",peligroso:false },
  632:{label: "GRILLETES",peligroso:false },
  633:{label: "GROUT ANCLAJE",peligroso:false },
  634:{label: "GRUA",peligroso:false },
  635:{label: "GRÚA HIDRÁULICA",peligroso:true },
  636:{label: "GRUA HORQUILLA",peligroso:true },
  637:{label: "GUANTE NITRILO",peligroso:false },
  638:{label: "GUANTE SOLDADOR",peligroso:false },
  639:{label: "GUANTES",peligroso:false },
  640:{label: "GUARDA LLAVE",peligroso:false },
  641:{label: "GUARDA MEDIDOR",peligroso:false },
  642:{label: "GUARDACABOS",peligroso:false },
  643:{label: "GUARDAPOLVOS Y JUNQUILLOS",peligroso:false },
  644:{label: "GUILLOTINA",peligroso:false },
  645:{label: "HDPE",peligroso:false },
  646:{label: "HENKEL",peligroso:false },
  647:{label: "HERRAMIENTAS",peligroso:false },
  648:{label: "HOJA DE PAPEL",peligroso:false },
  649:{label: "HOJA SIERRA",peligroso:false },
  650:{label: "HOJAS DE PORTON",peligroso:false },
  651:{label: "HOJAS DE SIERRA",peligroso:false },
  652:{label: "HORIZONTAL",peligroso:false },
  653:{label: "HORMIGON",peligroso:false },
  654:{label: "HORNO",peligroso:false },
  655:{label: "HORNOS DE SOLDADURA",peligroso:false },
  656:{label: "HUINCHAS",peligroso:false },
  657:{label: "ILUMINARIA",peligroso:false },
  658:{label: "IMPERMEABILIZANTES",peligroso:false },
  659:{label: "IMPRESORAS",peligroso:false },
  660:{label: "IMPRESORAS MULTIFUNCIONALES",peligroso:false },
  661:{label: "IMPRIMANTE",peligroso:false },
  662:{label: "INCLINOMETRO",peligroso:false },
  663:{label: "INSERTO PLASTICO",peligroso:false },
  664:{label: "INSERTOS Y PERFILES",peligroso:false },
  665:{label: "INSUMOS Y EPP",peligroso:false },
  666:{label: "INTERRUPTOR",peligroso:false },
  667:{label: "INYECTORES",peligroso:false },
  668:{label: "JARDINERAS",peligroso:false },
  669:{label: "JAULA CON BANDEJA",peligroso:false },
  670:{label: "JAULA METÁLICA",peligroso:false },
  671:{label: "JAULAS",peligroso:false },
  672:{label: "JUEGO ATORNILLADOR",peligroso:false },
  673:{label: "JUEGO DE DADOS",peligroso:false },
  674:{label: "JUEGO DE LLAVES",peligroso:false },
  675:{label: "JUEGO DE PRENSA",peligroso:false },
  676:{label: "JUEGO DESATORNILLADORES",peligroso:false },
  677:{label: "JUEGO MODULAR",peligroso:false },
  678:{label: "JUEGOS",peligroso:false },
  679:{label: "JUMBO AVANCE",peligroso:false },
  680:{label: "JUMBO BOOMER",peligroso:true },
  681:{label: "JUMBO FORTIFICACION",peligroso:false },
  682:{label: "JUMPER",peligroso:false },
  683:{label: "KAM",peligroso:false },
  684:{label: "KIT DE RESPUESTOS TORRE SOLAR",peligroso:false },
  685:{label: "KIT EQUIPOS CLIMATIZACION",peligroso:false },
  686:{label: "KIT INSTALACION WC",peligroso:false },
  687:{label: "KIT MANOMETRO",peligroso:false },
  688:{label: "KIT MANTENCION",peligroso:false },
  689:{label: "KIT PREFORMADOS",peligroso:false },
  690:{label: "KIT PROTEGOL",peligroso:false },
  691:{label: "KIT RADIO WALKIE TALKIE",peligroso:false },
  692:{label: "KIT REPUESTOS TORRES DE ENERGIZACION DE VIGILANCIA",peligroso:false },
  693:{label: "KIT TERMINACION INSTALACION DE FAENA",peligroso:false },
  694:{label: "LADRILLOS",peligroso:false },
  695:{label: "LANA INALÁMBRICA",peligroso:false },
  696:{label: "LANA MINERAL",peligroso:false },
  697:{label: "LAPICES",peligroso:false },
  698:{label: "LAPIZ",peligroso:false },
  699:{label: "LAVAMANOS PORTATIL",peligroso:false },
  700:{label: "LENTE",peligroso:false },
  701:{label: "LETREROS",peligroso:false },
  702:{label: "LIBRERIA",peligroso:false },
  703:{label: "LINEA DE VIDA",peligroso:false },
  704:{label: "LISTON MADERA",peligroso:false },
  705:{label: "LLANTA NEUMATICO",peligroso:false },
  706:{label: "LLANTAS",peligroso:false },
  707:{label: "LLAVE AJUSTABLE",peligroso:false },
  708:{label: "LLAVE AJUSTABLE CROMADA",peligroso:false },
  709:{label: "LLAVE CORTE ESPIGA",peligroso:false },
  710:{label: "LLAVE DE COLA",peligroso:false },
  711:{label: "LLAVE DE IMPACTO",peligroso:false },
  712:{label: "LLAVE DE TORQUE",peligroso:false },
  713:{label: "LLAVE IMPACTO",peligroso:false },
  714:{label: "LLAVE PUNTA CORONA",peligroso:false },
  715:{label: "LLAVES",peligroso:false },
  716:{label: "LOCKER",peligroso:false },
  717:{label: "LUMINARIA",peligroso:false },
  718:{label: "LUMINARIA POSTE Y CABLES",peligroso:false },
  719:{label: "MACETA",peligroso:false },
  720:{label: "MACHONES",peligroso:false },
  721:{label: "MADERA",peligroso:false },
  722:{label: "MADERAS Y PLANCHAS",peligroso:false },
  723:{label: "MALLA ELECTROSOLDADA",peligroso:false },
  724:{label: "MALLAS",peligroso:false },
  725:{label: "MALLAS DE SEGURIDAD",peligroso:false },
  726:{label: "MALLAS PARA CIERRE",peligroso:false },
  727:{label: "MANGA",peligroso:false },
  728:{label: "MANGUERA DE BOMBA Y DIAMEC U6",peligroso:false },
  729:{label: "MANGUERA PVC",peligroso:false },
  730:{label: "MANGUERAS",peligroso:false },
  731:{label: "MANGUETA",peligroso:false },
  732:{label: "MANLIFT",peligroso:false },
  733:{label: "MANTA AISLANTE",peligroso:false },
  734:{label: "MANUPULADOR TELESCOPICO",peligroso:true },
  735:{label: "MAQUINA DE SOLDAR",peligroso:false },
  736:{label: "MAQUINA DE TERMOFUSIÓN",peligroso:true },
  737:{label: "MAQUINA ELIPTICA",peligroso:false },
  738:{label: "MAQUINA INSPECTOR FATA LOGGER",peligroso:false },
  739:{label: "MARCADOR",peligroso:false },
  740:{label: "MARCO DE SIERRA",peligroso:false },
  741:{label: "MARCOS",peligroso:false },
  742:{label: "MARGINADORES",peligroso:false },
  743:{label: "MARTILLO DE PERFORACION",peligroso:true },
  744:{label: "MARTILLO DEMOLEDOR",peligroso:false },
  745:{label: "MARTILLOS",peligroso:false },
  746:{label: "MARTILLOS HIDRÁULICOS",peligroso:true },
  747:{label: "MASCACRA SOLDAR",peligroso:false },
  748:{label: "MÁSCARAS ARC FLESH",peligroso:false },
  749:{label: "MASCARILLA",peligroso:false },
  750:{label: "MATERIAL DE PIPING",peligroso:false },
  751:{label: "MATERIAL ELECTRICO",peligroso:false },
  752:{label: "MATERIAL LIBRERIA",peligroso:false },
  753:{label: "MATERIAL OPL",peligroso:false },
  754:{label: "MATERIALES",peligroso:false },
  755:{label: "MATERIALES E INSUMOS",peligroso:false },
  756:{label: "MATERIALES INTERNOS",peligroso:false },
  757:{label: "MATRILLO",peligroso:false },
  758:{label: "MAXISACOS",peligroso:false },
  759:{label: "MEDIDOR",peligroso:false },
  760:{label: "MEDIDOR DE GAS",peligroso:false },
  761:{label: "MEDIDOR DE GASES",peligroso:false },
  762:{label: "MEDIDOR ESPESOR BIDONES",peligroso:false },
  763:{label: "MELAMINA",peligroso:false },
  764:{label: "MEMBRANA CURADO.",peligroso:false },
  765:{label: "MENZULA",peligroso:false },
  766:{label: "MESA",peligroso:false },
  767:{label: "MESAS",peligroso:false },
  768:{label: "MESAS HORMIGON",peligroso:false },
  769:{label: "METAL",peligroso:false },
  770:{label: "MICROONDAS",peligroso:false },
  771:{label: "MILTIMETRO",peligroso:false },
  772:{label: "MINI BUS SPRINTER",peligroso:false },
  773:{label: "MINI CARGADO",peligroso:false },
  774:{label: "MINI MIXER",peligroso:false },
  775:{label: "MIRA ALUMINIO",peligroso:false },
  776:{label: "MOBIL",peligroso:false },
  777:{label: "MOCHILA",peligroso:false },
  778:{label: "MODEM",peligroso:false },
  779:{label: "MODULACION LOZA",peligroso:false },
  780:{label: "MODULO",peligroso:false },
  781:{label: "MODULO DE BAÑO",peligroso:false },
  782:{label: "MODULO LOSA",peligroso:false },
  783:{label: "MÓDULOS",peligroso:false },
  784:{label: "MOLDAJE",peligroso:false },
  785:{label: "MOLDAJES Y PLACAS FENOLICAS",peligroso:false },
  786:{label: "MOLDES CALZADA",peligroso:false },
  787:{label: "MOLDURA",peligroso:false },
  788:{label: "MONITOR",peligroso:false },
  789:{label: "MONTANTE",peligroso:false },
  790:{label: "MONTANTES",peligroso:false },
  791:{label: "MONTANTES Y CANALETAS",peligroso:false },
  792:{label: "MORDAZA",peligroso:false },
  793:{label: "MORDAZA GALVANIZADA",peligroso:false },
  794:{label: "MORRAL",peligroso:false },
  795:{label: "MORRALES DE CUERO",peligroso:false },
  796:{label: "MORTEROS",peligroso:false },
  797:{label: "MOSQUETON",peligroso:false },
  798:{label: "MOTONIVELADOR",peligroso:true },
  799:{label: "MOTONIVELADORA",peligroso:true },
  800:{label: "MOTOR COMPRESOR",peligroso:true },
  801:{label: "MOTOSOLDADORAS",peligroso:true },
  802:{label: "MUEBLE",peligroso:false },
  803:{label: "MUEBLE MURAL",peligroso:false },
  804:{label: "MUFA",peligroso:false },
  805:{label: "MUFA UNION TRIPOL",peligroso:false },
  806:{label: "MULTIMETRO",peligroso:false },
  807:{label: "MULTIPUNTO",peligroso:false },
  808:{label: "MURO",peligroso:false },
  809:{label: "MUROS DE CONTENCIÓN",peligroso:false },
  810:{label: "MUROS PREFABRICADOS",peligroso:false },
  811:{label: "NAPOLEON",peligroso:false },
  812:{label: "NEUMATICOS",peligroso:false },
  813:{label: "NEUMÁTICOS",peligroso:false },
  814:{label: "NEW JERSEY",peligroso:false },
  815:{label: "NEW YERSEY",peligroso:false },
  816:{label: "NICHO DE GAS",peligroso:false },
  817:{label: "NIPLERA",peligroso:false },
  818:{label: "NIPLERAS",peligroso:false },
  819:{label: "NIPLES",peligroso:false },
  820:{label: "NITROGENO LÍQUIDO",peligroso:false },
  821:{label: "NIVEL",peligroso:false },
  822:{label: "NIVEL TOPOGRAFICO",peligroso:false },
  823:{label: "NOTEBOOK",peligroso:false },
  824:{label: "ONTANTE",peligroso:false },
  825:{label: "OREJAS DE IZAJE",peligroso:false },
  826:{label: "OSB",peligroso:false },
  827:{label: "OT",peligroso:false },
  828:{label: "OVEROL",peligroso:false },
  829:{label: "PACKING",peligroso:false },
  830:{label: "PACKS DE AGUA",peligroso:false },
  831:{label: "PALLETS",peligroso:false },
  832:{label: "PALMETAS",peligroso:false },
  833:{label: "PALOS",peligroso:false },
  834:{label: "PANCHUELA",peligroso:false },
  835:{label: "PANEL",peligroso:false },
  836:{label: "PANEL PREFABRICADO",peligroso:false },
  837:{label: "PANEL TRAPEZOIDAL",peligroso:false },
  838:{label: "PANELES",peligroso:false },
  839:{label: "PANELES DE MADERA",peligroso:false },
  840:{label: "PANELES FOTOVOLTAICOS",peligroso:false },
  841:{label: "PANTALLAS",peligroso:false },
  842:{label: "PAÑOS",peligroso:false },
  843:{label: "PAÑOS DE LIMPIEZA",peligroso:false },
  844:{label: "PAÑOS MECANICOS",peligroso:false },
  845:{label: "PAPEL",peligroso:false },
  846:{label: "PAPEL FOTOCOPIA",peligroso:false },
  847:{label: "PAPEL MURAL",peligroso:false },
  848:{label: "PAPEL PARA FOTOCOPIADORAS",peligroso:false },
  849:{label: "PAPEL VINILO",peligroso:false },
  850:{label: "PAPELERIA",peligroso:false },
  851:{label: "PAQUETES DE BARRAS HQ",peligroso:false },
  852:{label: "PARARRAYOS",peligroso:false },
  853:{label: "PARCHES",peligroso:false },
  854:{label: "PARRILLAS",peligroso:false },
  855:{label: "PARRILLAS Y ESMALTES",peligroso:false },
  856:{label: "PASADOR CONICO",peligroso:false },
  857:{label: "PASAMANOS",peligroso:false },
  858:{label: "PASARELAS",peligroso:false },
  859:{label: "PASTA MURO",peligroso:false },
  860:{label: "PASTA PARA SOLDAR",peligroso:false },
  861:{label: "PASTELON",peligroso:false },
  862:{label: "PASTELONES",peligroso:false },
  863:{label: "PATINES DE FRENO",peligroso:false },
  864:{label: "PAYWELDER",peligroso:true },
  865:{label: "PC PRECOM",peligroso:false },
  866:{label: "PEDESTAL",peligroso:false },
  867:{label: "PERFIL ALUMINIO",peligroso:false },
  868:{label: "PERFIL ANGULO",peligroso:false },
  869:{label: "PERFIL CUADRADO",peligroso:false },
  870:{label: "PERFILERIA",peligroso:false },
  871:{label: "PERFILES",peligroso:false },
  872:{label: "PERFORADORA",peligroso:false },
  873:{label: "PERLON",peligroso:false },
  874:{label: "PERMANIT",peligroso:false },
  875:{label: "PERNO HELICOIDAL",peligroso:false },
  876:{label: "PERNOS",peligroso:false },
  877:{label: "PERNOS DE ANCLAJE",peligroso:false },
  878:{label: "PERTIGA",peligroso:false },
  879:{label: "PESCANTES",peligroso:false },
  880:{label: "PIEDRA",peligroso:false },
  881:{label: "PIEDRA CHUNGARA",peligroso:false },
  882:{label: "PIEDRAS",peligroso:false },
  883:{label: "PIEDRAS FABRICADAS",peligroso:false },
  884:{label: "PIEZAS",peligroso:false },
  885:{label: "PILARES",peligroso:false },
  886:{label: "PINO",peligroso:false },
  887:{label: "PINO BRUTO",peligroso:false },
  888:{label: "PINO VERDE",peligroso:false },
  889:{label: "PINTA CAL",peligroso:false },
  890:{label: "PINTURAS",peligroso:false },
  891:{label: "PINZAS AMPERIMETRICAS",peligroso:false },
  892:{label: "PINZAS DE BLOQUEO",peligroso:false },
  893:{label: "PIOLA",peligroso:false },
  894:{label: "PIPEWELDER",peligroso:false },
  895:{label: "PIPING",peligroso:false },
  896:{label: "PISO",peligroso:false },
  897:{label: "PISO FLOTANTE",peligroso:false },
  898:{label: "PISO VINILICO",peligroso:false },
  899:{label: "PISTOLA CALAFATERA",peligroso:false },
  900:{label: "PISTOLA CALAFATORES",peligroso:false },
  901:{label: "PISTOLA DE DISPARO",peligroso:false },
  902:{label: "PISTOLA DE PINTAR",peligroso:false },
  903:{label: "PISTOLA MIG",peligroso:false },
  904:{label: "PISTOLAS",peligroso:false },
  905:{label: "PIZARRA",peligroso:false },
  906:{label: "PIZARRA ACRILICO",peligroso:false },
  907:{label: "PLACA",peligroso:false },
  908:{label: "PLACA ATIESADORES DE CONEXIÓN",peligroso:false },
  909:{label: "PLACA COMPACTADORA",peligroso:false },
  910:{label: "PLACA FENOLICAS",peligroso:false },
  911:{label: "PLACAS",peligroso:false },
  912:{label: "PLACAS FENÓLICAS",peligroso:false },
  913:{label: "PLACAS OSB",peligroso:false },
  914:{label: "PLACAS Y EEMM DE REFUERZOS",peligroso:false },
  915:{label: "PLANA",peligroso:false },
  916:{label: "PLANA BOTADORA",peligroso:false },
  917:{label: "PLANCHA AISLAPOL",peligroso:false },
  918:{label: "PLANCHA DE YESO",peligroso:false },
  919:{label: "PLANCHA DIAMANTADA",peligroso:false },
  920:{label: "PLANCHA LISA",peligroso:false },
  921:{label: "PLANCHA SIDING FIBROCEMENTO",peligroso:false },
  922:{label: "PLANCHA TERCIADO",peligroso:false },
  923:{label: "PLANCHA TRASL",peligroso:false },
  924:{label: "PLANCHA ZING",peligroso:false },
  925:{label: "PLANCHAS",peligroso:false },
  926:{label: "PLANCHAS ACUSTICAS",peligroso:false },
  927:{label: "PLANCHAS AISLAPOL",peligroso:false },
  928:{label: "PLANCHAS DE HOJALATAS",peligroso:false },
  929:{label: "PLANCHAS FENOLICAS",peligroso:false },
  930:{label: "PLANCHAS METÁLICAS",peligroso:false },
  931:{label: "PLANCHAS OSB",peligroso:false },
  932:{label: "PLANCHAS ZING",peligroso:false },
  933:{label: "PLANCHUELAS",peligroso:false },
  934:{label: "PLATAFORMA",peligroso:false },
  935:{label: "PLATAFORMA DE ACERO",peligroso:false },
  936:{label: "PLATAFORMA DE TIJERA",peligroso:false },
  937:{label: "PLATAFORMA METALICA",peligroso:false },
  938:{label: "PLATAFORMA ROBUST",peligroso:false },
  939:{label: "PLATAFORMAS",peligroso:false },
  940:{label: "PLATAFORMAS AMPLIROLL",peligroso:false },
  941:{label: "PLETINA",peligroso:false },
  942:{label: "PLETINAS",peligroso:false },
  943:{label: "PLUMILLA",peligroso:false },
  944:{label: "POLEA",peligroso:false },
  945:{label: "POLEAS",peligroso:false },
  946:{label: "POLIESTIRENO",peligroso:false },
  947:{label: "POLIETILENO",peligroso:false },
  948:{label: "POLINES",peligroso:false },
  949:{label: "POLIPROPILENO",peligroso:false },
  950:{label: "POLIURETANO",peligroso:false },
  951:{label: "PORCELANATO",peligroso:false },
  952:{label: "PORCELANATO TEJAS",peligroso:false },
  953:{label: "PORTA BICICLETAS",peligroso:false },
  954:{label: "PORTA BIT",peligroso:false },
  955:{label: "PORTA PALETT",peligroso:false },
  956:{label: "PORTA STUBEND",peligroso:false },
  957:{label: "PORTAPAPELES",peligroso:false },
  958:{label: "PORTAPRISMA",peligroso:false },
  959:{label: "PORTON",peligroso:false },
  960:{label: "PORTONES",peligroso:false },
  961:{label: "POSTE",peligroso:false },
  962:{label: "POSTES",peligroso:false },
  963:{label: "POSTES DE TOLDO",peligroso:false },
  964:{label: "POSTES METÁLICOS",peligroso:false },
  965:{label: "PREFABRICADOS",peligroso:false },
  966:{label: "PRENSA",peligroso:false },
  967:{label: "PRENSAS",peligroso:false },
  968:{label: "PROTECCION ESLINGAS",peligroso:false },
  969:{label: "PROTECTOR SOLAR",peligroso:false },
  970:{label: "PROYECTOR",peligroso:false },
  971:{label: "PROYECTOR LED",peligroso:false },
  972:{label: "PUERTA",peligroso:false },
  973:{label: "PUERTA MAFOR",peligroso:false },
  974:{label: "PUERTAS",peligroso:false },
  975:{label: "PUERTAS METALICAS",peligroso:false },
  976:{label: "PULPO",peligroso:false },
  977:{label: "PUNTAL",peligroso:false },
  978:{label: "PUNTALES",peligroso:false },
  979:{label: "PURIFICADOR DE AIRE",peligroso:false },
  980:{label: "PVC",peligroso:false },
  981:{label: "RADIOS",peligroso:false },
  982:{label: "RASTRILLO",peligroso:false },
  983:{label: "RECEPTACULOS",peligroso:false },
  984:{label: "RECTIFICADOR",peligroso:false },
  985:{label: "REDES",peligroso:false },
  986:{label: "REDUCCION",peligroso:false },
  987:{label: "REDUCTOR EMISIONES",peligroso:false },
  988:{label: "REFRIGERADOR",peligroso:false },
  989:{label: "REFUERZO ESTRUCTURAS",peligroso:false },
  990:{label: "REGLA ALUMINIO",peligroso:false },
  991:{label: "REGULADOR CARGA",peligroso:false },
  992:{label: "REJA BODEGA",peligroso:false },
  993:{label: "REJAS PERIMETRALES",peligroso:false },
  994:{label: "REMACHES",peligroso:false },
  995:{label: "REMADORA",peligroso:false },
  996:{label: "REMANENTES",peligroso:false },
  997:{label: "REPISAS",peligroso:false },
  998:{label: "REPLEX",peligroso:false },
  999:{label: "REPUESTOS",peligroso:false },
  1000:{label: "REPUESTOS MAQUINARIA",peligroso:false },
  1001:{label: "RESINA",peligroso:false },
  1002:{label: "RETRACTIL",peligroso:false },
  1003:{label: "RETROEXCAVADORA",peligroso:true },
  1004:{label: "REVELADOR",peligroso:false },
  1005:{label: "REVESTIMIENTOS",peligroso:false },
  1006:{label: "RIELES",peligroso:false },
  1007:{label: "RIELES Y CODO HDPE",peligroso:false },
  1008:{label: "RODAMIENTOS",peligroso:false },
  1009:{label: "RODAPIE LATERAL",peligroso:false },
  1010:{label: "RODILLO COMPACTADOR",peligroso:true },
  1011:{label: "RODILLO DOBLE TAMBOR",peligroso:true },
  1012:{label: "RODILLOS",peligroso:true },
  1013:{label: "RODILLOS Y PLACAS",peligroso:false },
  1014:{label: "RODONADORA",peligroso:true },
  1015:{label: "ROLLO ALAMBRE",peligroso:false },
  1016:{label: "ROLLO CORTINA",peligroso:false },
  1017:{label: "ROLLO DE CABLE",peligroso:false },
  1018:{label: "ROLLO FILM",peligroso:false },
  1019:{label: "ROLLOS",peligroso:false },
  1020:{label: "ROLLOS DE CABLE",peligroso:false },
  1021:{label: "ROLLOS DE LANA MINERAL",peligroso:false },
  1022:{label: "ROLLOS POLIETILENOS",peligroso:false },
  1023:{label: "ROPA",peligroso:false },
  1024:{label: "ROPA CORPORATIVA",peligroso:false },
  1025:{label: "ROPA PARA SOLDAR",peligroso:false },
  1026:{label: "ROPA TÉRMICA",peligroso:false },
  1027:{label: "ROSCADORA",peligroso:false },
  1028:{label: "ROSETA",peligroso:false },
  1029:{label: "ROTOMARTILLO",peligroso:false },
  1030:{label: "SACABOCADO",peligroso:false },
  1031:{label: "SACO ASPILLERO",peligroso:false },
  1032:{label: "SACO DE CEMENTO",peligroso:false },
  1033:{label: "SACO DE KAM",peligroso:false },
  1034:{label: "SACO MASILLA",peligroso:false },
  1035:{label: "SACOS",peligroso:false },
  1036:{label: "SACOS DE YESO",peligroso:false },
  1037:{label: "SACOS MASILLA",peligroso:false },
  1038:{label: "SACOS VOLCAFIX",peligroso:false },
  1039:{label: "SALA DE MONITOREO",peligroso:false },
  1040:{label: "SANITARIOS",peligroso:false },
  1041:{label: "SATELITES",peligroso:false },
  1042:{label: "SCOOP",peligroso:false },
  1043:{label: "SELLO",peligroso:false },
  1044:{label: "SELLO DE SEGURIDAD",peligroso:false },
  1045:{label: "SEÑALETICA",peligroso:false },
  1046:{label: "SEPARADOR HORMIGON",peligroso:false },
  1047:{label: "SEPARADORES",peligroso:false },
  1048:{label: "SERRUCHO",peligroso:false },
  1049:{label: "SET ASADO",peligroso:false },
  1050:{label: "SET ATORNILLADORES",peligroso:false },
  1051:{label: "SET DE LLAVES",peligroso:false },
  1052:{label: "SIDEBOOM",peligroso:false },
  1053:{label: "SIERRA",peligroso:false },
  1054:{label: "SIKA",peligroso:false },
  1055:{label: "SIKA ANTISOL",peligroso:false },
  1056:{label: "SIKA GROT",peligroso:false },
  1057:{label: "SIKA GROUT",peligroso:false },
  1058:{label: "SIKADUR",peligroso:false },
  1059:{label: "SIKAFILL",peligroso:false },
  1060:{label: "SILICONA",peligroso:false },
  1061:{label: "SILLA",peligroso:false },
  1062:{label: "SMART TV",peligroso:false },
  1063:{label: "SOLDADORAS",peligroso:false },
  1064:{label: "SOLDADURA",peligroso:false },
  1065:{label: "SOLERA",peligroso:false },
  1066:{label: "SOLERAS",peligroso:false },
  1067:{label: "SOLERILLA",peligroso:false },
  1068:{label: "SONDA",peligroso:false },
  1069:{label: "SONDA VIBRADORA",peligroso:true },
  1070:{label: "SONDAJE",peligroso:true },
  1071:{label: "SOPLADOR",peligroso:true },
  1072:{label: "SOPORTE",peligroso:false },
  1073:{label: "SOPORTE DE MONTAJE",peligroso:false },
  1074:{label: "SOPORTE ESCALERILLA",peligroso:false },
  1075:{label: "SOPORTE GRILLETE",peligroso:false },
  1076:{label: "SOPORTE PORTACARRETE",peligroso:false },
  1077:{label: "SOPORTE SCU",peligroso:false },
  1078:{label: "SOPORTE TINA",peligroso:false },
  1079:{label: "SOPORTE TRAPECIO",peligroso:false },
  1080:{label: "SOPORTES",peligroso:false },
  1081:{label: "SOPORTES DROP",peligroso:false },
  1082:{label: "SOPORTES ESCALERILLA",peligroso:false },
  1083:{label: "SOPORTES PARA VENTILADORES",peligroso:false },
  1084:{label: "SPOOL",peligroso:true },
  1085:{label: "SPOOLS",peligroso:true },
  1086:{label: "SPRAY",peligroso:false },
  1087:{label: "SPRINTER",peligroso:true },
  1088:{label: "STUB",peligroso:false },
  1089:{label: "SUMINISTROS",peligroso:false },
  1090:{label: "SUPERLIFT",peligroso:true },
  1091:{label: "TABIQUE",peligroso:false },
  1092:{label: "TABIQUERIA",peligroso:false },
  1093:{label: "TABLAS",peligroso:false },
  1094:{label: "TABLERO TERCIADO",peligroso:false },
  1095:{label: "TABLEROS",peligroso:false },
  1096:{label: "TABLEROS ELÉCTRICOS",peligroso:false },
  1097:{label: "TABLONES",peligroso:false },
  1098:{label: "TACOS",peligroso:false },
  1099:{label: "TALADRO",peligroso:false },
  1100:{label: "TALONARIOS",peligroso:false },
  1101:{label: "TAMBOR",peligroso:false },
  1102:{label: "TAMBORES DE ACEITE",peligroso:false },
  1103:{label: "TAMBORES METÁLICOS",peligroso:false },
  1104:{label: "TANQUE DE AGUA",peligroso:false },
  1105:{label: "TAPA",peligroso:false },
  1106:{label: "TAPA ALCANTARILLADO",peligroso:false },
  1107:{label: "TAPA DE CAMARA",peligroso:false },
  1108:{label: "TAPAGORRO",peligroso:false },
  1109:{label: "TAPAS",peligroso:false },
  1110:{label: "TAPAS DE CAMARAS",peligroso:false },
  1111:{label: "TAPAS DE HORMIGON",peligroso:false },
  1112:{label: "TAPAS DE PVC",peligroso:false },
  1113:{label: "TAPAS Y ANILLOS DE CÁMARAS SANITARIAS",peligroso:false },
  1114:{label: "TAPON PLASTICO",peligroso:false },
  1115:{label: "TAPON PVC",peligroso:false },
  1116:{label: "TAQUIMETRO",peligroso:false },
  1117:{label: "TARJETA CONTROL",peligroso:false },
  1118:{label: "TAZAS",peligroso:false },
  1119:{label: "TECHO LISTO",peligroso:false },
  1120:{label: "TECHO SYNTEC",peligroso:false },
  1121:{label: "TECHUMBRE",peligroso:false },
  1122:{label: "TECLE PALANCA",peligroso:false },
  1123:{label: "TECLES",peligroso:false },
  1124:{label: "TEE",peligroso:false },
  1125:{label: "TEFLON",peligroso:false },
  1126:{label: "TEJAS",peligroso:false },
  1127:{label: "TEJAS ASFALTICAS",peligroso:false },
  1128:{label: "TELEVISOR",peligroso:false },
  1129:{label: "TENSOR",peligroso:false },
  1130:{label: "TEODOLITO",peligroso:false },
  1131:{label: "TERCIADO",peligroso:false },
  1132:{label: "TERMINAL",peligroso:false },
  1133:{label: "TERMINALES",peligroso:false },
  1134:{label: "TERMUFUSIONADORA",peligroso:true },
  1135:{label: "TEST DROGAS",peligroso:false },
  1136:{label: "TESTIGUERA",peligroso:false },
  1137:{label: "TIERRA VEGETAL",peligroso:false },
  1138:{label: "TIJERA LATA",peligroso:false },
  1139:{label: "TINA",peligroso:false },
  1140:{label: "TINETAS",peligroso:false },
  1141:{label: "TINTAS",peligroso:false },
  1142:{label: "TIRANTES",peligroso:false },
  1143:{label: "TOLDOS",peligroso:false },
  1144:{label: "TONER IMPRESORA",peligroso:false },
  1145:{label: "TOPE DE ESTACIONAMIENTO",peligroso:false },
  1146:{label: "TORNILLO",peligroso:false },
  1147:{label: "TORRES DE ENERGIZACIÓN DE VIGILANCIA",peligroso:false },
  1148:{label: "TORRES DE ILUMINACIÓN",peligroso:false },
  1149:{label: "TORRETAS",peligroso:false },
  1150:{label: "TRACTO",peligroso:true },
  1151:{label: "TRACTO CAMION",peligroso:true },
  1152:{label: "TRACTO CAMION RAMPLA",peligroso:true },
  1153:{label: "TRANSFORMADOR",peligroso:true },
  1154:{label: "TRANSFORMADOR Y CELDA",peligroso:true },
  1155:{label: "TRAPOS LIMPIEZA",peligroso:false },
  1156:{label: "TRASPALETA",peligroso:false },
  1157:{label: "TREPADOR",peligroso:false },
  1158:{label: "TRIPODE",peligroso:false },
  1159:{label: "TRONZADORA",peligroso:true },
  1160:{label: "TUBERIA",peligroso:false },
  1161:{label: "TUBERIA HDPE",peligroso:false },
  1162:{label: "TUBERIA PVC",peligroso:false },
  1163:{label: "TUBO ACERO",peligroso:false },
  1164:{label: "TUBO CEMENTO",peligroso:false },
  1165:{label: "TUBO DE ANCLAJE",peligroso:false },
  1166:{label: "TUBO DE PERFORACION",peligroso:false },
  1167:{label: "TUBOS",peligroso:false },
  1168:{label: "TUBOS HDPE",peligroso:false },
  1169:{label: "TUBOS PVC",peligroso:false },
  1170:{label: "TUERCA",peligroso:false },
  1171:{label: "TUERCAS",peligroso:false },
  1172:{label: "UNIDAD DE FUERZA",peligroso:false },
  1173:{label: "VALVULA",peligroso:false },
  1174:{label: "VARILLAS",peligroso:false },
  1175:{label: "VASELINA",peligroso:false },
  1176:{label: "VASOS",peligroso:false },
  1177:{label: "VENTANAS",peligroso:false },
  1178:{label: "VENTILADORES",peligroso:false },
  1179:{label: "VIBROPISÓN",peligroso:false },
  1180:{label: "VIDRIO HUMO",peligroso:false },
  1181:{label: "VIDRIOS",peligroso:false },
  1182:{label: "VIGAS",peligroso:false },
  1183:{label: "VIGAS DE PINO",peligroso:false },
  1184:{label: "VOLCANITA",peligroso:false },
  1185:{label: "WC",peligroso:false },
  1186:{label: "WC CADIZ",peligroso:false },
  1187:{label: "WD",peligroso:false },
  1188:{label: "WINCH",peligroso:false },
  1189:{label: "YESO",peligroso:false },
  1190:{label: "YESO CARTÓN",peligroso:false },
  1191:{label: "YESO ESPUMA",peligroso:false },
  1192:{label: "YUGO",peligroso:false },
  1193:{label: "ZAPATA ACERO",peligroso:false },
  1194:{label: "ZIFA",peligroso:false },
  1195:{label: "ZINCALUM",peligroso:false },
//"aqui termina el listado de salfa",
}

// const ListaCargasGeneral
export const ListaCargasGeneral = Object.fromEntries( Object.entries(ListaCargasCompleta).filter(([key]) =>
  key >=0 && key <=109
));

export const ListaCargasSalfa = Object.fromEntries( Object.entries(ListaCargasCompleta).filter(([key]) =>
  (key >=110 && key <=1195) || key === 0
));

export const carga_listado_select = (username_cliente)=>{
  if(SALFA.includes(username_cliente)) return ListaCargasSalfa
  else return ListaCargasGeneral
}