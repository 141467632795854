import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ListaCargas } from "../../../Utils/Selects";
import NumberWithCommas from "../../../Utils/NumberWithCommas";
import { useTranslation } from 'react-i18next';
import { ListaCargasCompleta } from "../../../Utils/ListaCargas";
const user = JSON.parse(localStorage.getItem("subcargo_user_loged"))


export default function CargasSeguimiento({ Recorrido, ValorDeCarga, Solicitud, CargaATransportar }) {

    const [modalState, setModalState] = useState(true)
    const { t } = useTranslation("ver_solicitud")


    const handleModal = () => {
        setModalState(!modalState)
    }


    var descargas = []
    var cargas_tot = []

    useEffect(() => {
    }, [modalState])

    return (
        <div>
            {modalState ?

                <div className="w-full bg-white border rounded-md px-4 py-3" style={{ borderColor: '#EE4452' }}>
                    <div className="flex justify-end float-right">
                        <FontAwesomeIcon icon={faChevronUp} color="#EE4452" size={"lg"}
                            className=" cursor-pointer float-right mr-1"
                            onClick={() => { handleModal() }}
                        />
                    </div>
                    <div className="text-azul font-bold">Carga y descarga</div>

                    {Recorrido.map((recorrido, index) => {

                        var cargas = []
                        if (recorrido.tipo === "Descarga" && !(index === recorrido.length - 1) && Array.isArray(recorrido.carga)) recorrido.carga.forEach((carga) =>
                            descargas.push(carga.label)
                        )
                        if (recorrido.tipo === "Carga" && !(index === recorrido.length - 1) && Array.isArray(recorrido.carga)) recorrido.carga.forEach((carga) =>
                            cargas_tot.push(carga.label)
                        )

                        if (index === Recorrido.length - 1) {
                            var _cargas_tot = [...cargas_tot]

                            _cargas_tot.filter((carga) => {
                                return (
                                    !descargas.includes(carga.label))
                            })
                        }
                        if (Array.isArray(recorrido.carga)) recorrido.carga.forEach((carga) => (
                            carga?.label ? cargas.push(carga.label)
                                : null

                        )

                        )
                        var UnicaDescarga = recorrido.tipo === "Descarga" && descargas.length <= 1
                        return (

                            <>
                                <div className="text-rojo2 font-bold my-4">{recorrido.tipo}</div>
                                <div>

                                    <div className="flex flex-row">
                                        <label className="w-2/5">Dirección de carga</label>
                                        <div className="bg-input text-input w-3/5 mb-1 px-2">{recorrido.Direccion}</div>
                                    </div>
                                    <div style={UnicaDescarga ? { display: 'none' } : null} className="flex flex-row">
                                        <label className="w-2/5">Carga a transportar</label>
                                        <div className="bg-input text-input w-3/5 mb-1 px-2">{
                                            cargas && cargas.length > 0 ?
                                                cargas.join(', ') :
                                                !Array.isArray(recorrido.carga) ?
                                                    ListaCargasCompleta[recorrido.carga].label :
                                                    index === Recorrido.length - 1 && _cargas_tot ?

                                                        _cargas_tot.join(', ') :
                                                        "Sin especificar"

                                        }
                                        </div>
                                    </div>

                                    <div style={UnicaDescarga ? { display: 'none' } : null} className="flex flex-row">
                                        <label className="w-2/5">Descripción de carga</label>
                                        <div className="bg-input text-input w-3/5 mb-1 px-2">{recorrido.descripcion ? recorrido.descripcion : CargaATransportar}</div>
                                    </div>
                                    <div style={UnicaDescarga ? { display: 'none' } : null} className="flex flex-row">
                                        <label className="w-2/5">Valor de carga</label>
                                        <div className="bg-input text-input w-3/5 mb-1 px-2">
                                            {Recorrido.length === 2 && !Recorrido.descripcion ?
                                                t("$") + ` ${NumberWithCommas(ValorDeCarga)} ${Solicitud.MonedaTransaccion}`
                                                :
                                                recorrido.valorcarga ?
                                                    t("$") + ` ${NumberWithCommas(recorrido.valorcarga)} ${Solicitud.MonedaTransaccion}`
                                                    :
                                                    "Sin Especificar"
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/5">Nombre de contacto</label>
                                        <div className="bg-input text-input w-3/5 mb-1 px-2">{recorrido.nombre}</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/5">Teléfono de contacto</label>
                                        <div className="bg-input text-input w-3/5 mb-1 px-2">{recorrido.telefono}</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/5">Correo de contacto</label>
                                        <div className="bg-input text-input w-3/5 mb-1 px-2">{recorrido.correo}</div>
                                    </div>
                                </div>
                            </>
                        )

                    })}


                </div>
                : <div className="px-4 pt-2 pb-4 rounded-md" style={{ backgroundColor: '#EEEBEB' }}>
                    <div className="flex justify-end float-right">
                        <FontAwesomeIcon icon={faChevronDown} color="#EE4452" size={"lg"}
                            className=" cursor-pointer float-right mr-1"
                            onClick={() => { handleModal() }}
                        />
                    </div>
                    <div className="text-azul font-bold">Carga y descarga</div>
                    <div className="flex flex-row gap-x-8">
                        <label className="w-2/5">Carga</label>
                        <label className="justify-start font-bold">{Recorrido.length > 0 ? Recorrido[0].Direccion : "-"}</label>
                    </div>
                </div>
            }
        </div>
    )
}
