import { Alert } from './Layouts/AlertLayout'
import cargandoCamion from '../../Asset/img/cargandoCamion.gif'

export const AlertaSuccess = (texto='', options={}) => Alert.mixin({
    icon: 'success',
    title: '¡Éxito!',
    html: texto,
    confirmButtonText: 'Cerrar',
    customClass:{
        confirmButton: 'rounded-xl bg-[#EE4452]  text-white py-1 px-8 hover:bg-[#FF2222] transition duration-300',
    },
    ...options
})  

export const AlertaConfirmacion = (texto = '') => Alert.mixin({
    icon: 'warning',
    title: '¿Está seguro?',
    html: texto,
    showCancelButton:true,
    cancelButtonText: 'Cancelar',
    reverseButtons:true,
})

export const AlertaLoading = Alert.mixin({
    width: '350px',
    title: `<img src=${cargandoCamion} alt='camion cargando' />`,
    showConfirmButton:false,
    customClass:'h-[20vh] content-center'
})

export const AlertaError = (texto = 'Ha ocurrido un error', buttonText = "Volver") => Alert.mixin({
    icon: 'error',
    title: 'Error',
    html: texto,
    confirmButtonText: buttonText,
    customClass:{
        confirmButton: 'rounded-xl bg-[#EE4452] text-white py-1 px-8 hover:bg-[#FF2222] transition duration-300',
    }
})

export const AlertaErrorToken = () => Alert.mixin({
    icon: 'error',
    title: 'Error',
    html: 'Su sesión ha expirado, por favor vuelva a iniciar sesión.',
    confirmButtonText: 'Ir al Login',
    customClass:{
        confirmButton: 'rounded-xl bg-[#EE4452] text-white py-1 px-8 hover:bg-[#FF2222] transition duration-300',
    }
}).fire().then((result)=>{
    if(result.isConfirmed){
    localStorage.clear()
    window.location.reload()
    }
})

export const AlertaConfirmacionTarifario = (texto = '') => Alert.mixin({
    icon: 'warning',
    title: '¿Está seguro?',
    html: texto,
    showCancelButton:true,
    cancelButtonText: 'Cancelar',
    showBolsaButton: true,
    reverseButtons:true,
    customClass:{
        cancelButton: 'rounded-xl bg-[#002758] text-white mr-8 py-2 px-12 hover:bg-[#3C9AC5] transition duration-300',
        confirmButton: 'rounded-xl bg-[#002758] text-white py-2 px-12 hover:bg-[#3C9AC5] transition duration-300',
        denyButton: 'rounded-xl bg-[#002758] text-white mr-8 py-2 px-8 hover:bg-[#3C9AC5] transition duration-300',
    },
    bolsaButtonText: 'Bolsa'
})

export const AlertaConfirmacionOptimizarRuta = (texto = '') => Alert.mixin({
    icon: 'warning',
    title: '¿Desea optimizar su ruta?',
    html: texto,
    showCancelButton:true,
    cancelButtonText: 'Continuar sin optimizar',
    confirmButtonText: 'Optimizar',
    reverseButtons:true,
})