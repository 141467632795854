// import React from 'react'
// import {Marker, GoogleMap, LoadScript } from '@react-google-maps/api'

// class Map extends React.Component {
//   constructor(props){
//     super(props)
//     this.state = {
//       ubications: this.props.ubications
//     }
//     // console.log(process.env)
//   }
//   render(){



//       return(
//         <LoadScript
//         id="script-loader"
//         googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
//       >
//         <GoogleMap
//           id='example-map'
//           mapContainerStyle={{
//             height: '80vh',
//             width: '80vw'
//           }}
//           zoom={ 12}
//           center={this.props.center ? this.props.center : {lat: parseFloat(this.props.lat),lng: parseFloat(this.props.lng)}}
//         >
//         {
//           this.props.ubications ? 
//           this.props.ubications.map((ubication)=> {
//             return (
//               <Marker
//               onLoad={marker => {
//                 console.log()

//               }}
//               position={{
//                 lat: parseFloat(ubication.lat),
//                 lng: parseFloat(ubication.lng)
//               }}


//             />
//             )})
//             :

//               <Marker
//               onLoad={marker => {
//                 console.log()
//                 // console.log('marker: ', marker)
//               }}
//               position={{
//                 lat: parseFloat(this.props.lat),
//                 lng: parseFloat(this.props.lng)
//               }}
//               // label={contrato.safi}
//               // icon={ blue_marker}
//             />

//         }


//         </GoogleMap>
//       </LoadScript>
//       )


//   }
// }

// export default Map


import React from 'react';
import {
    GoogleMap,
    LoadScript,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    Marker,
} from "@react-google-maps/api"

//import Recorrido from './Recorrido'
// import Recorrido from './../Cliente/Recorrido'
// import InfoRecorrido from '../Cliente/InfoRecorrido'
const libraries = ["places"]



class MapSeguimiento extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            response: null,
            travelMode: 'DRIVING',
            origin: this.props.origin ? this.props.origin : null,
            destination: this.props.destination ? this.props.destination : null,
            destinations_coordinates: null,
            origins_coordinates: null,
            render_map: true

        }

        this.onClick = this.onClick.bind(this)
        this.onMapClick = this.onMapClick.bind(this)
    }


    onClick() {
        // if (this.origin.value !== '' && this.destination.value !== '') {
        this.setState(
            () => ({
                render_map: true
                // origin: this.origin.value,
                // destination: this.destination.value
            })
        )
        // }
    }

    onMapClick(...args) {
        console.log('onClick args: ', args)
    }


    render() {
        const waypoints = this.props.recorrido.slice(1, this.props.recorrido.length - 1)
            .map((item) => {
                let obj = { location: item.Direccion, stopover: true }
                return obj
            })

        const inicio = this.props.recorrido[0].Direccion
        const termino = this.props.recorrido[this.props.recorrido.length - 1].Direccion

        return (



            <LoadScript
                id="script-loader"
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                libraries={libraries}
            >

                <div className="flex flex-col flex-grow w-full">
                    <GoogleMap
                        // required
                        id='direction-example1'
                        // required
                        mapContainerStyle={{
                            height: '87vh',
                            width: '100%',
                        }}
                        zoom={12}
                        center={{ lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng) }}


                        // required

                        // required

                        // optional
                        onClick={this.onMapClick}
                        // optional
                        onLoad={map => {
                            console.log('DirectionsRenderer onLoad map: ', map)
                        }}
                        // optional
                        onUnmount={map => {
                            console.log('DirectionsRenderer onUnmount map: ', map)
                        }}
                    >


                        <Marker
                            onLoad={marker => {
                                console.log()
                                // console.log('marker: ', marker)
                            }}
                            position={{
                                lat: parseFloat(this.props.lat),
                                lng: parseFloat(this.props.lng)
                            }}
                        // label="Now"
                        // label={contrato.safi}
                        // icon={ 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'}
                        />

                        {
                            this.state.render_map ?
                                <DirectionsService
                                    // required
                                    options={{
                                        destination: termino,
                                        waypoints: waypoints,
                                        origin: inicio,
                                        travelMode: 'DRIVING'
                                    }}
                                    callback={
                                        (response) => {
                                            console.log(response)
                                            if (response.status === 'OK') {
                                                this.setState(
                                                    {
                                                        response: response,
                                                        render_map: false
                                                    }
                                                )
                                            }
                                        }
                                    }
                                />
                                :
                                null
                        }

                        {
                            this.state.response !== null ?
                                <DirectionsRenderer
                                    // required
                                    options={{
                                        directions: this.state.response
                                    }}
                                    // optional
                                    onLoad={directionsRenderer => {
                                        console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                                    }}
                                    // optional
                                    onUnmount={directionsRenderer => {
                                        console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                                    }}
                                />
                                :
                                null
                        }
                        {
                            this.state.origins_coordinates !== null
                                && this.state.destinations_coordinates !== null ?
                                <DistanceMatrixService
                                    options={{
                                        destinations: this.state.destinations_coordinates,
                                        origins: this.state.origins_coordinates,
                                        travelMode: "DRIVING",
                                        unitSystem: "METRIC"
                                    }}
                                    callback={(response) => { console.log(response) }}
                                />
                                :
                                null
                        }

                    </GoogleMap>

                </div>






            </LoadScript>

        )
    }
}

export default MapSeguimiento