import numberWithCommas from '../../../Utils/NumberWithCommas'
//eslint-disable-next-line no-unused-vars
function rucValido(ruc) {
    //11 dígitos y empieza en 10,15,16,17 o 20
    
    // if (!(ruc >= 1e10 && ruc < 11e9
    //    || ruc >= 15e9 && ruc < 18e9
    //    || ruc >= 2e10 && ruc < 21e9))
    //     return false;
    
    for (var suma = -(ruc%10<2), i = 0; i<11; i++, ruc = ruc/10|0)
        suma += (ruc % 10) * (i % 7 + (i/7|0) + 1);
    return suma % 11 === 0;
    
}

export function formatoPeru(rut){
        if(!rut || rut?.length>20) return null
        var ruc = rut.replace(/[-.,[\]()\s]+/g,"")
        //Es entero?    

        // if ((ruc = Number(ruc)) && ruc % 1 === 0
        //     && rucValido(ruc)) { // ⬅️ Acá se comprueba
        //     return ruc;
        // } else {
        //     return null
        // }
        return ruc
  }

export function formatoBrasil(rut){
    if (rut===null) return null
    const nuevoRut = rut.replace(/\./g,'').replace(/-/g, '').trim().toLowerCase();
    const ultimos2Digitos = nuevoRut.substr(-2,2);
    const rutDigito = nuevoRut.substr(0, nuevoRut.length-2)
    let format = numberWithCommas(rutDigito)
    if(rut==="subcargoadmi-n") return "SubcargoAdmin"
    if (rut.length!==0) return format.concat('-').concat(ultimos2Digitos);
    else return null
  }

  export function formatoPanama(rut){
    // XX.XXX.XXX-X
    if (rut===null) return null
    const nuevoRut = rut.replace(/\./g,'').replace(/\-/g, '').trim().toLowerCase();
    const ultimoDigito = nuevoRut.substr(-1, 1);
    const rutDigito = nuevoRut.substr(0, nuevoRut.length-1)
    //let format = '';
    // for (let i = rutDigito.length; i > 0; i--) {
    //     const e = rutDigito.charAt(i-1);
    //     format = e.concat(format);
    //     if (i % 3 === 0){
    //       console.log("i:", i)
    //         format = '.'.concat(format);
    //     }
    // }
    let format = numberWithCommas(rutDigito)
    if(rut==="subcargoadmi-n") return "SubcargoAdmin"
    if (rut.length!=0) return format.concat('-').concat(ultimoDigito);
    else return null
  }

  export function formatoMexico(rut){
    // XX.XXX.XXX-X
    if (rut===null) return null
    return rut.toUpperCase()
  }