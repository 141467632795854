import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export default function PopupLayout({ children, title,close }) {

    return (
       <main>
            <div className="bg-azul rounded-t-lg h-full text-white text-center w-full py-2">
                <div className="justify-center items-center flex flex-row mr-10">
                    <div className="text-3xl font-bold w-full text-center">{title}</div>     
                    <FontAwesomeIcon  className="text-3xl cursor-pointer" color="#FFFFFF" icon={faTimesCircle} onClick={() => close()} />
                </div>
            </div>
            <div className="bg-white h-full w-full">
                {children}
            </div>
      </main>

    )
}
