import React,{Fragment, Suspense, useEffect} from 'react';
import {HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { changeLanguage } from 'i18next';
import { Languages } from './Utils/Languages';
import cargandoCamion from './Asset/img/cargandoCamion.gif';
import SolicitudTiempoRealTecnoFast from './Pages/SolicitudTiempoRealTecnoFast';
import { TRANSPORTISTAS_GPS_VISIBLE, TRANSPORTISTAS_FINANZAS_VISIBLE, TRANSPORTISTAS_FINANZAS_PERU_VISIBLE } from './Utils/Selects';
import NumeroDeContratosTrabajador from './Utils/NumeroDeContratosTrabajador';
import LoadingCamion from './Components/Shared/LoadingCamion';
const ModuloCelulas = React.lazy(()=> import /* webpackChunkName: "Escritorio" */('./Pages/ModuloCelulas'))
const Escritorio = React.lazy(()=> import /* webpackChunkName: "Escritorio" */('./Pages/Escritorio'))
const MisConductores = React.lazy(()=> import /* webpackChunkName: "MisConductores" */('./Pages/MisConductores'))
const FleteDeRetorno = React.lazy(()=> import /* webpackChunkName: "FleteDeRetorno" */('./Pages/FleteDeRetorno'))
const FleteDeRetornoCliente = React.lazy(()=> import /* webpackChunkName: "FleteDeRetornoCliente" */('./Pages/FleteDeRetornoCliente'))
const FleteDeRetornoAdmin = React.lazy(()=> import /* webpackChunkName: "FleteDeRetornoAdmin" */('./Pages/FleteDeRetornoAdmin'))
const CrearFleteRetorno = React.lazy(()=> import /* webpackChunkName: "CrearFleteRetorno" */('./Pages/CrearFleteRetorno'))
const ConductoresAdmin = React.lazy(()=> import /* webpackChunkName: "ConductoresAdmin" */('./Pages/ConductoresAdmin'))
const MisCamiones = React.lazy(()=> import /* webpackChunkName: "MisCamiones" */('./Pages/MisCamiones'))
const MisAcoples = React.lazy(()=> import /* webpackChunkName: "MisAcoples" */('./Pages/MisAcoples'))
const DatosTransportista = React.lazy(()=> import /* webpackChunkName: "DatosTransportista" */('./Pages/DatosTransportista'))
const OfertasTransportista = React.lazy(()=> import /* webpackChunkName: "MisOfertas" */('./Pages/MisOfertas'))
const ProfileTransportista = React.lazy(()=> import /* webpackChunkName: "ProfileTransportista" */('./Pages/ProfileTransportista'))
const CalendarioTransportista = React.lazy(()=> import /* webpackChunkName: "CalendarioTransportista" */('./Pages/CalendarioTransportista'))
const PerfilConductorCamionAcoplado = React.lazy(()=> import /* webpackChunkName: "PerfilConductorCamionAcoplado" */('./Pages/PerfilConductorCamionAcoplado'))
const ReporteriaTransportista = React.lazy(()=> import /* webpackChunkName: "ReporteriaTransportista" */('./Pages/ReporteriaTransportista'))
const Reporteria = React.lazy(()=> import /* webpackChunkName: "Reporteria" */('./Pages/Reporteria'))
const ReporteriaPro = React.lazy(()=> import /* webpackChunkName: "ReporteriaPro" */('./Pages/ReporteriaPro'))
const MisSolicitudes = React.lazy(()=> import /* webpackChunkName: "MisSolicitudes" */('./Pages/MisSolicitudes'))
const MisSolicitudesInvitadoContrato = React.lazy(()=> import /* webpackChunkName: "MisSolicitudesInvitadoContrato" */('./Pages/MisSolicitudesInvitadoContrato'))
const TrabajadoresClientes = React.lazy(()=> import /* webpackChunkName: "TrabajadoresClientes" */('./Pages/TrabajadoresClientes'))
const DestinatariosFrecuentes = React.lazy(()=> import /* webpackChunkName: "DestinatariosFrecuentes" */('./Pages/DestinatariosFrecuentes'))
const Contratos = React.lazy(()=> import /* webpackChunkName: "Contratos" */('./Pages/Contratos'))
const Linea = React.lazy(()=> import /* webpackChunkName: "Linea" */('./Pages/Linea'))
const Profile = React.lazy( ()=> import (  /* webpackChunkName: "Profile" */'./Pages/Profile'))
const Desvinculacion = React.lazy( ()=> import (  /* webpackChunkName: "Desvinculacion" */'./Pages/Desvinculacion'))
const Calendario = React.lazy( ()=> import (  /* webpackChunkName: "Calendario" */'./Pages/Calendario'))
const MisServicios = React.lazy( ()=> import (  /* webpackChunkName: "MisServicios" */'./Pages/MisServicios'))
const CrearSolicitud = React.lazy( ()=> import (  /* webpackChunkName: "CrearSolicitud" */'./Pages/CrearSolicitud'))
const CamionesAdmin  = React.lazy( ()=> import ( /* webpackChunkName: "MisCamionesAdmin2" */'./Pages/MisCamionesAdmin2'))
const AcoplesAdmin  = React.lazy( ()=> import ( /* webpackChunkName: "Acoples" */'./Pages/Acoples'))
const TransportistasAdmin  = React.lazy( ()=> import ( /* webpackChunkName: "Transportistas" */'./Pages/Transportistas'))
const ClientesAdmin  = React.lazy( ()=> import ( /* webpackChunkName: "Clientes" */'./Pages/Clientes'))
const GpsAdmin = React.lazy( ()=> import ( /* webpackChunkName: "Gpsadmin" */'./Pages/Gpsadmin'))
const SolicitudesAdmin  = React.lazy( ()=> import ( /* webpackChunkName: "MisSolicitudesAdmin" */'./Pages/MisSolicitudesAdmin'))
const SolicitudesVisita  = React.lazy( ()=> import ( /* webpackChunkName: "SolicitudesVisita" */'./Pages/SolicitudesVisita'))
const Comisiones  = React.lazy( ()=> import ( /* webpackChunkName: "ComisionYDatos" */'./Pages/ComisionYDatos'))
const Admins  = React.lazy( ()=> import ( /* webpackChunkName: "Admins" */'./Pages/Admins'))
const Login = React.lazy ( ()=> import (  /* webpackChunkName: "Login" */'./Pages/Login'))
const VerificarEmail = React.lazy ( ()=> import (  /* webpackChunkName: "VerificarEmail" */'./Pages/VerificarEmail'))
const RecuperarClave = React.lazy ( ()=> import (  /* webpackChunkName: "RecuperarClave" */'./Pages/RecuperarClave'))
const CambiarClave = React.lazy ( ()=> import (  /* webpackChunkName: "CambiarClave" */'./Pages/CambiarClave'))
const RegistroNuevo = React.lazy ( ()=> import (  /* webpackChunkName: "RegistroNuevo" */'./Pages/RegistroNuevo'))
const RegistroPersona = React.lazy ( ()=> import ( /* webpackChunkName: "RegistroPersona" */ './Components/RegistroPersona/RegistroPersona'))
const RegistroTransportista = React.lazy ( ()=> import (  /* webpackChunkName: "RegistroTransportista" */ './Components/RegistroTransportista/RegistroTransportista'))
const RegistroEmpresa = React.lazy ( ()=> import ( /* webpackChunkName: "RegistroEmpresa" */ './Components/RegistroEmpresa/RegistroEmpresa'))
const AddTransportistaAdmin = React.lazy ( ()=> import (  /* webpackChunkName: "AddTransportistaAdmin2" */'./Pages/AddTransportistaAdmin2'))
const EstandarMineroAdmin = React.lazy ( ()=> import (  /* webpackChunkName: "EstandarMineroAdmin" */'./Pages/EstandarMineroAdmin'))
const AddEstandarMinero = React.lazy ( ()=> import (  /* webpackChunkName: "AddEstandarMineroAdmin" */'./Pages/AddEstandarMineroAdmin'))
const SolicitudTiempoReal = React.lazy ( ()=> import (  /* webpackChunkName: "SolicitudTiempoReal" */'./Pages/SolicitudTiempoReal'))
const FinanzasAdmin = React.lazy ( ()=> import (  /* webpackChunkName: "FinanzasAdmin" */'./Pages/FinanzasAdmin'))
const FinanzasTransportista = React.lazy ( ()=> import (  /* webpackChunkName: "FinanzasTransportista" */'./Pages/FinanzasTransportista'))
const FinanzasCliente = React.lazy ( ()=> import (  /* webpackChunkName: "FinanzasCliente" */'./Pages/FinanzasCliente'))
const AddSolicitudTransportista = React.lazy ( ()=> import (  /* webpackChunkName: "AddSolicitudTransportista" */'./Pages/AddSolicitudTransportista'))
const DestinatariosFrecuentesFreemium = React.lazy ( ()=> import (  /* webpackChunkName: "DestinatariosFrecuentesFreemium" */'./Pages/DestinatariosFrecuentesFreemium'))
const PagoExitoso = React.lazy( ()=> import( /* webpackChunkName: "PagoExitoso" */ './Pages/PagoExitoso'))
const LogsGps = React.lazy( ()=> import( /* webpackChunkName: "LogsGps" */ './Pages/LogsGps'))
const LogsGps2 = React.lazy( ()=> import( /* webpackChunkName: "LogsGps2" */ './Pages/LogsGps2'))
const Logout = React.lazy( ()=> import( /* webpackChunkName: "Logout" */ './Pages/Logout'))
const CompartirGps = React.lazy( ()=> import( /* webpackChunkName: "CompartirGps" */ './Pages/CompartirGps'))
const ReporteriaPorContrato = React.lazy( ()=> import( /* webpackChunkName: "CompartirGps" */ './Components/ReporteriaPro/graficosPorContrato'))
const userLoged = JSON.parse(localStorage.getItem("subcargo_user_loged"))

const Child = (props)=>{
  console.log("props.match.params",props.match.params)
  // localStorage.removeItem("subcargo_user_loged")
  const es_coordinador = userLoged?.perfil_admin?.perfil === "Coordinador" || userLoged?.perfil_admin?.perfil =="Supervisor Coordinación" || userLoged?.perfil_admin?.perfil =="Administrador Operaciones"
  if(localStorage.getItem("subcargo_user_loged")){   
    if(props.match.params.id === 'logout') return <Suspense fallback={<LoadingCamion/>}> <Logout {...props}/> </Suspense>
    if(props.match.params.id === "verificar_email") return   <Suspense fallback={<LoadingCamion/>}> <VerificarEmail {...props}/></Suspense>

    if(userLoged.EsSubcargo){
      //if(props.match.params.id === "admin") return   <Suspense fallback={<LoadingCamion/>}>  <ProfileAdmin {...props}/> </Suspense>
      if(props.match.params.id === "busco_carga") return   <Suspense fallback={<LoadingCamion/>}>  <FleteDeRetornoAdmin {...props}/> </Suspense>
      if(props.match.params.id === "crear_busco_carga") return   <Suspense fallback={<LoadingCamion/>}>  <CrearFleteRetorno {...props}/> </Suspense>

      
      if(props.match.params.id === "camiones_admin") return <Suspense fallback={<LoadingCamion/>}>  <CamionesAdmin {...props}/> </Suspense>
      if(props.match.params.id === "acoples_admin") return <Suspense fallback={<LoadingCamion/>}>  <AcoplesAdmin {...props}/> </Suspense>
      if(props.match.params.id === "conductores") return <Suspense fallback={<LoadingCamion/>}>  <ConductoresAdmin {...props}/> </Suspense>
      if(props.match.params.id === "transportistas_admin") return <Suspense fallback={<LoadingCamion/>}>  <TransportistasAdmin {...props}/> </Suspense>
      if(props.match.params.id === "clientes_admin") return  <Suspense fallback={<LoadingCamion/>}>  <ClientesAdmin {...props}/> </Suspense>
      if(props.match.params.id === "admins") return <Suspense fallback={<LoadingCamion/>}>  <Admins {...props}/> </Suspense>
      if(props.match.params.id === "solicitudes_admin") return <Suspense fallback={<LoadingCamion/>}>  <SolicitudesAdmin {...props}/> </Suspense>
      if(props.match.params.id === "estandar_minero_admin") return <Suspense fallback={<LoadingCamion/>}>  <EstandarMineroAdmin {...props}/>      </Suspense>
      if(props.match.params.id === "comisiones") return <Suspense fallback={<LoadingCamion/>}>  <Comisiones {...props}/> </Suspense>
      if(props.match.params.id === "add_estandar_minero_admin") return <Suspense fallback={<LoadingCamion/>}>  <AddEstandarMinero {...props}/></Suspense>
      if(props.match.params.id === "add_transportista_admin") return <Suspense fallback={<LoadingCamion/>}>  <AddTransportistaAdmin {...props}/></Suspense>
      if(props.match.params.id === "gps_admin") return <Suspense fallback={<LoadingCamion/>}>  <GpsAdmin {...props}/> </Suspense>

      if(props.match.params.id === "escritorio") return <Suspense fallback={<LoadingCamion/>}> <Escritorio {...props} /></Suspense>
      if(props.match.params.id === "modulo_celulas") return <Suspense fallback={<LoadingCamion/>}> <ModuloCelulas {...props} /></Suspense>

      if(es_coordinador){
        if(props.match.params.id === "solicitudes") return <Suspense fallback={<LoadingCamion/>}> <MisSolicitudes {...props}/></Suspense>
        if(props.match.params.id === "solicitud_form") return <Suspense fallback={<LoadingCamion/>}>  <CrearSolicitud {...props}/> </Suspense>
      }
      
      if(props.match.params.id === "camiones_admin") return   <Suspense fallback={<LoadingCamion/>}>  <CamionesAdmin {...props}/> </Suspense>
      if(props.match.params.id === "acoples_admin") return   <Suspense fallback={<LoadingCamion/>}>  <AcoplesAdmin {...props}/> </Suspense>
      if(props.match.params.id === "conductores") return   <Suspense fallback={<LoadingCamion/>}>  <ConductoresAdmin {...props}/> </Suspense>
      if(props.match.params.id === "transportistas_admin") return   <Suspense fallback={<LoadingCamion/>}>  <TransportistasAdmin {...props}/> </Suspense>
      if(props.match.params.id === "clientes_admin") return   <Suspense fallback={<LoadingCamion/>}>  <ClientesAdmin {...props}/> </Suspense>
      if(props.match.params.id === "admins") return   <Suspense fallback={<LoadingCamion/>}>  <Admins {...props}/> </Suspense>
      if(props.match.params.id === "solicitudes_admin") return   <Suspense fallback={<LoadingCamion/>}>  <SolicitudesAdmin {...props}/> </Suspense>
      if(props.match.params.id === "estandar_minero_admin") return   <Suspense fallback={<LoadingCamion/>}>  <EstandarMineroAdmin {...props}/>      </Suspense>
      if(props.match.params.id === "comisiones") return   <Suspense fallback={<LoadingCamion/>}>  <Comisiones {...props}/> </Suspense>
      
      if(props.match.params.id === "add_estandar_minero_admin") return   <Suspense fallback={<LoadingCamion/>}>  <AddEstandarMinero {...props}/></Suspense>
      
      if(props.match.params.id === "add_transportista_admin") return   <Suspense fallback={<LoadingCamion/>}>  <AddTransportistaAdmin {...props}/></Suspense>
      //if(props.match.params.id === "add_cliente_admin") return   <Suspense fallback={<LoadingCamion/>}>  <AddClienteAdmin {...props}/></Suspense>
      // if(props.match.params.id === "add_admin") return   <Suspense fallback={<LoadingCamion/>}>  <AddAdmin {...props}/></Suspense>
      if(props.match.params.id === "finanzas_admin") return   <Suspense fallback={<LoadingCamion/>}>  <FinanzasAdmin {...props}/> </Suspense>
      // if(props.match.params.id === "chat") return <Chat {...props}/>
      else return <Redirect to="/transportistas_admin" />
    }
    if(userLoged.EsTransportista){
      if(userLoged.Estado === "Operativo"){
          if(props.match.params.id === "crear_busco_carga") return   <Suspense fallback={<LoadingCamion/>}>  <CrearFleteRetorno {...props}/> </Suspense>
          if(props.match.params.id === "busco_carga") return   <Suspense fallback={<LoadingCamion/>}>  <FleteDeRetorno {...props}/> </Suspense>
        
        if(props.match.params.id === "calendario_transportista") return <Suspense fallback={<LoadingCamion/>}>  <CalendarioTransportista {...props}/> </Suspense>
        if(props.match.params.id === "ofertas") return <Suspense fallback={<LoadingCamion/>}>  <OfertasTransportista {...props}/> </Suspense>
        if(props.match.params.id === "servicios") return <Suspense fallback={<LoadingCamion/>}>  <MisServicios {...props}/> </Suspense>
        if(props.match.params.id === "solicitudes") return <Suspense fallback={<LoadingCamion/>}>  <MisServicios {...props}/> </Suspense>
        if(props.match.params.id === "perfil_transportista") return <Suspense fallback={<LoadingCamion/>}>  <ProfileTransportista {...props}/> </Suspense>
        if(props.match.params.id === "transportista") return <Suspense fallback={<LoadingCamion/>}> <DatosTransportista {...props}/></Suspense>
        if(props.match.params.id === "perfil_conductor_camion_acoplado") return <Suspense fallback={<LoadingCamion/>}>  <PerfilConductorCamionAcoplado {...props}/></Suspense>
        if(props.match.params.id === "reporteria_transportista") return <Suspense fallback={<LoadingCamion/>}>  <ReporteriaTransportista {...props}/></Suspense>
        if(props.match.params.id === "add_solicitud_transportista") return <Suspense fallback={<LoadingCamion/>}> <AddSolicitudTransportista {...props} /></Suspense>
        if (TRANSPORTISTAS_GPS_VISIBLE.includes(userLoged.username)){
          if(props.match.params.id === "gps_transportista") return <Suspense fallback={<LoadingCamion/>}>  <GpsAdmin {...props}/> </Suspense>
        }
        
        // if(props.match.params.id === "chat") return <Chat {...props}/>
      }
      if(userLoged.Pais == "Chile" || userLoged.Pais == "Perú")//TRANSPORTISTAS_FINANZAS_PERU_VISIBLE.includes(userLoged.username))
      if(props.match.params.id === "finanzas_transportista") return   <Suspense fallback={<LoadingCamion/>}>  <FinanzasTransportista {...props}/> </Suspense>


      if(props.match.params.id === 'desvinculacion') return <Suspense fallback={<LoadingCamion/>}> <Desvinculacion {...props}/> </Suspense>
      if(props.match.params.id === "conductores") return <Suspense fallback={<LoadingCamion/>}>  <MisConductores {...props}/> </Suspense>
      if(props.match.params.id === "camiones") return <Suspense fallback={<LoadingCamion/>}>  <MisCamiones {...props}/> </Suspense>
      if(props.match.params.id === "acoples") return <Suspense fallback={<LoadingCamion/>}>  <MisAcoples {...props}/> </Suspense>
      if(props.match.params.id === "destinatarios_transportista") return <Suspense fallback={<LoadingCamion/>}> <DestinatariosFrecuentesFreemium {...props}/></Suspense>
      // if(props.match.params.id === "chat") return <Chat {...props}/>

      else return <Redirect to="/camiones" />
    }
    if(userLoged.EsCliente || userLoged.EsTrabajador){
        if(props.match.params.id === "busco_carga_cliente") return <Suspense fallback={<LoadingCamion/>}> <FleteDeRetornoCliente {...props}/> </Suspense>
      
      if(props.match.params.id === "finanzas_cliente") return   <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <FinanzasCliente {...props}/> </Suspense>
      if(props.match.params.id === "perfil") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <Profile {...props}/> </Suspense>
      if(props.match.params.id === "calendario") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <Calendario {...props}/> </Suspense>
      if(props.match.params.id === "solicitud_form") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <CrearSolicitud {...props}/> </Suspense>
      if(props.match.params.id === "destinatarios") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}> <DestinatariosFrecuentes {...props}/></Suspense>
      if(props.match.params.id === "trabajadores_cliente") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}> <TrabajadoresClientes {...props}/></Suspense>
      if(props.match.params.id === "contratos") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}> <Contratos {...props}/></Suspense>
      if(props.match.params.id === "solicitudes") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}> <MisSolicitudes {...props}/></Suspense>
      if(props.match.params.id === "solicitudes_invitado_contrato") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}> <MisSolicitudesInvitadoContrato {...props}/></Suspense>
      if(props.match.params.id === "linea") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}> <Linea {...props}/></Suspense>
      if(props.match.params.id === "perfil_conductor_camion_acoplado") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <PerfilConductorCamionAcoplado {...props}/></Suspense>
      // if(userLoged.EsTrabajador && !userLoged.vistaCliente && NumeroDeContratosTrabajador(userLoged)>0){
      //   if(props.match.params.id === "reporteria") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <Reporteria {...props}/></Suspense>
      // }
      if((userLoged.EsCliente || userLoged.vistaCliente || userLoged.trabajadorHolding) && !(userLoged._id == "63876846020442e171e24a44" ||  userLoged.Cliente =="63876846020442e171e24a44")){
        if(props.match.params.id === "reporteria") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <Reporteria {...props}/></Suspense>
      } 

      if( userLoged._id == "63876846020442e171e24a44" || (userLoged.vistaCliente && userLoged.Cliente =="63876846020442e171e24a44")){
        if(props.match.params.id === "reporteria_pro") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <ReporteriaPro {...props}/></Suspense>
        if(props.match.params.id === "reporteria_graficos") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <ReporteriaPorContrato {...props}/></Suspense>
      }
     
      if(props.match.params.id === "solicitudes_visita") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <SolicitudesVisita {...props}/></Suspense>
      if(props.match.params.id === "gps_cliente") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}>  <GpsAdmin {...props}/> </Suspense>
        // if(props.match.params.id === "chat") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}> <Chat {...props}/></Suspense>
      if(props.match.params.id === "ConfirmacionPago") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}> <PagoExitoso {...props}/></Suspense>
      else return <Redirect to="/solicitudes" />      
    }
    // if(props.match.params.id === "editar_perfil") return  <EditarPerfil {...props}/> 
    // if(props.match.params.id === "editar_conductor") return  <EditarChofer {...props}/> 
    // if(props.match.params.id === "editar_camion") return  <EditarCamion {...props}/> 
    
  }else{
    if(props.match.params.id === 'logout') return <Suspense fallback={<LoadingCamion/>}> <Logout {...props}/> </Suspense>
    if(props.match.params.id === 'desvinculacion') return <Suspense fallback={<LoadingCamion/>}> <Desvinculacion {...props}/> </Suspense>
    if(props.match.params.id === "login") return <Suspense fallback={<LoadingCamion/>}> <Login {...props}/></Suspense>
    if(props.match.params.id === "solicitudes") return <Suspense fallback={<div className="bg-gray-100 m-0 h-screen grid place-items-center"><img src={cargandoCamion} alt='Cargando...' className="w-96 h-96"/></div>}> <Login {...props}/></Suspense>
    if(props.match.params.id === "solicitud_tiempo_real") return <Suspense fallback={<LoadingCamion/>}> <SolicitudTiempoReal {...props}/></Suspense>
    if(props.match.params.id === "solicitud_tiempo_real_tecnofast") return <Suspense fallback={<LoadingCamion/>}> <SolicitudTiempoRealTecnoFast {...props}/></Suspense>
    if(props.match.params.id === "verificar_email") return <Suspense fallback={<LoadingCamion/>}> <VerificarEmail {...props}/></Suspense>
    if(props.match.params.id === "recuperar_clave") return <Suspense fallback={<LoadingCamion/>}> <RecuperarClave {...props}/></Suspense>
    if(props.match.params.id === "cambiar_clave") return <Suspense fallback={<LoadingCamion/>}> <CambiarClave {...props}/></Suspense>
    //if(props.match.params.id === "nuevo_cliente") return <Suspense fallback={<LoadingCamion/>}> <AddCliente {...props}/></Suspense>
    if(props.match.params.id === "registro") return <Suspense fallback={<LoadingCamion/>}> <RegistroNuevo {...props}/></Suspense>
    if(props.match.params.id === "registro_persona") return <Suspense fallback={<LoadingCamion/>}> <RegistroPersona {...props}/></Suspense>
    if(props.match.params.id === "registro_transportista") return <Suspense fallback={<LoadingCamion/>}> <RegistroTransportista {...props}/></Suspense>
    if(props.match.params.id === "registro_empresa") return <Suspense fallback={<LoadingCamion/>}> <RegistroEmpresa {...props}/></Suspense>
    if(props.match.params.id === "logs_gps") return <Suspense fallback={<LoadingCamion/>}> <LogsGps {...props}/></Suspense>
    if(props.match.params.id === "logs_gps2") return <Suspense fallback={<LoadingCamion/>}> <LogsGps2 {...props}/></Suspense>
    if(props.match.params.id === "share_gps") return <Suspense fallback={<LoadingCamion/>}> <CompartirGps {...props}/></Suspense>

    //if(props.match.params.id === "nueva_persona_natural") return <Suspense fallback={<div>Cargando...</div>}> <AddPersonaNatural{...props}/></Suspense>
    //if(props.match.params.id === "nuevo_transportista") return <Suspense fallback={<div>Cargando...</div>}> <AddTransportista {...props}/></Suspense>
    // if(props.match.params.id === "chat") return <Chat {...props}/>
    else return <Redirect to="/login" />
  } 
}


function App() {
  const LanguageChanger = () =>{
    changeLanguage(Languages[userLoged.Pais])
  }
  useEffect(() => {
      if(userLoged) LanguageChanger()
    } ,[])
  return (
    <div className=''>
        <Router>
          <Switch>
                <Fragment>
                  <Route exact path='/:id' render={Child}/>
                  <Route exact path='/' render={Child}/>
                </Fragment>
          </Switch>
        </Router>
    </div>

  );
}

export default App;



